@import "../../Theme";
@import "../../_variables";

.core-progress-block {
  line-height: 0;

  .ant-progress-circle-trail {
    stroke: @grey-60;
  }

  &.ant-progress-status-exception
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: @red-100;
  }

  &.ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: @lime-100;
  }

  &.dev-env {
    &.ant-progress-status-success
      .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke: @purple-100;
    }
  }

  &.dev-env {
    &.ant-progress-status-success
      .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke: @purple-100;
    }
  }

  &.ant-progress-line.ant-progress-status-active {
    .ant-progress-bg {
      background-color: @green-100;
    }
    &.dev-env {
      .ant-progress-bg {
        background-color: @purple-100;
      }
    }
  }

  &.ant-progress-line.ant-progress-status-normal {
    .ant-progress-bg {
      background-color: @green-100;
      border-radius: 0;
    }
    .ant-progress-inner {
      border-radius: 0;
    }
    &.dev-env {
      .ant-progress-bg {
        background-color: @purple-100;
      }
    }
  }

  &.mini {
    .ant-progress-inner {
      width: 24px !important;
      height: 24px !important;
    }

    .ant-progress-text {
      display: none;
    }
  }

  &.small {
    .ant-progress-inner {
      width: 48px !important;
      height: 48px !important;
    }

    .ant-progress-text {
      color: @grey-140;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &.medium {
    .ant-progress-inner {
      width: 96px !important;
      height: 96px !important;
    }

    .ant-progress-text {
      color: @grey-140;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.large {
    .ant-progress-inner {
      width: 128px !important;
      height: 128px !important;
    }

    .ant-progress-text {
      color: @grey-140;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &.ant-progress-circle.ant-progress-status-normal {
    .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke: @green-100;
    }
    &.dev-env {
      .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: @purple-100;
      }
    }
  }

  &.ant-progress-status-success {
    .ant-progress-bg {
      background-color: @lime-100;
    }

    .ant-progress-text {
      color: @lime-100;
    }
  }

  &.dev-env {
    &.ant-progress-status-success {
      .ant-progress-bg {
        background-color: @purple-100;
      }

      .ant-progress-text {
        color: @purple-100;
      }
    }
  }

  &.ant-progress-status-exception {
    .ant-progress-bg {
      background-color: @red-100;
    }

    .ant-progress-text {
      color: @red-100;
    }
  }

  &.square {
    .ant-progress-inner {
      border-radius: 0;

      .ant-progress-bg {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.finished {
      .ant-progress-inner {
        border-radius: 0;
      }
    }
  }

  &.ant-progress-small {
    .ant-progress-bg {
      height: 4px !important;
    }

    .ant-progress-text {
      font-size: 11px;

      .anticon {
        font-size: 11px;
      }
    }
  }

  &.ant-progress-default.ant-progress-line {
    .ant-progress-bg {
      height: 6px !important;
    }

    .ant-progress-text {
      font-size: 12px;

      .anticon {
        font-size: 12px;
      }
    }
  }
}
