@import "../../Theme";

.arrow-button {
  background: @white;
  border: 1px solid @grey-100;
  height: inherit;

  &.default {
    padding: 0px 8px;
  }

  &.small {
    border: none;
    background: none;
    padding: 0px 4px;

    &.disabled {
      cursor: not-allowed;

      .anticon {
        color: @grey-100;
      }
    }
  }

  .anticon {
    color: @grey-120;
  }

  &:not(.disabled) {
    &:hover {
      border: 1px solid @green-100;

      .anticon {
        color: @green-100;
      }
    }

    &:active {
      border: 1px solid @green-160;

      .anticon {
        color: @green-160;
      }
    }
  }

  &.disabled:not(.small) {
    border: 1px solid @grey-80;
    background-color: @grey-20;
    cursor: not-allowed;

    .anticon {
      color: @grey-100;
    }
  }
}

.arrow-button-prev {
  border-radius: 16px 2px 2px 16px;
}

.arrow-button-next {
  border-radius: 2px 16px 16px 2px;
}

.pagination {
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item {
    margin-right: 4px;
  }

  .ant-pagination-simple {
    .ant-pagination-simple-pager input {
      background: @white;
      border: 1px solid @grey-120;
      border-radius: 20px;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      .anticon {
        color: @grey-120;
      }

      .arrow-button.default {
        border: none;
        background: none;
      }

      .arrow-button.small {
        border: none;
        background: none;
      }

      &.ant-pagination-disabled {
        .arrow-button.default {
          border: none;
          background: none;
          color: @grey-80;
          cursor: not-allowed;
        }

        .arrow-button.small {
          border: none;
          background: none;
          color: @grey-80;
          cursor: not-allowed;
        }
      }
    }
  }

  .ant-pagination-prev.ant-pagination-disabled,
  .ant-pagination-next.ant-pagination-disabled {
    .anticon {
      color: @grey-100;
    }

    .arrow-button.default {
      border: 1px solid @grey-80;
      background-color: @grey-20;
      cursor: not-allowed;
    }

    .arrow-button.small {
      border: none;
      background: none;
      cursor: not-allowed;
    }
  }

  .ant-pagination:not(.ant-pagination-disabled) {
    &.mini {
      .ant-pagination-item:not(.ant-pagination-item-active) {
        border: none;
        background: none;
      }
    }

    .ant-pagination-item:not(.ant-pagination-item-active) {
      border-radius: 2px;
      background: @white;
      border: 1px solid @grey-100;
      font-weight: 600;

      a {
        color: @grey-140;
      }

      &:hover {
        border-color: @green-100;

        a {
          color: @green-100;
        }
      }

      &:active {
        background: @green-160;
        border-color: @green-160;

        a {
          color: @white;
        }
      }
    }

    .ant-pagination-item-ellipsis {
      color: @grey-140;
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: none;
      border-radius: 0px;
      color: @green-100;

      &:hover {
        border: 1px solid @green-100;
      }

      &:active {
        border: 1px solid @green-160;

        .ant-pagination-item-link-icon {
          color: @green-160;
        }
      }
    }
  }

  .ant-pagination-disabled:not(.mini) .ant-pagination-item {
    background: @grey-20;
    border: 1px solid @grey-80;
    border-radius: 2px;
    font-weight: 600;

    a {
      color: @grey-100;
    }
  }

  .ant-pagination-disabled.mini .ant-pagination-item {
    background: none;
    border: none;

    a {
      color: @grey-100;
    }
  }

  .ant-pagination-item-active {
    background: @green-100;
    border: 1px solid @green-120;
    border-radius: 2px;
    font-weight: 700;

    a {
      color: @white;
    }
  }

  .ant-pagination.ant-pagination-disabled {
    .ant-pagination-options .ant-select .ant-select-selector {
      border: 1px solid @grey-80;
      background-color: @grey-20;
      color: @grey-80;
    }

    .ant-pagination-options .ant-pagination-options-quick-jumper {
      input {
        border: 1px solid @grey-80;
        background-color: @grey-20;
        color: @grey-80;
      }
    }
  }

  .ant-pagination-options .ant-select .ant-select-selector {
    background: @white;
    border: 1px solid @grey-120;
    border-radius: 20px;
  }

  .ant-pagination-options
    .ant-select:not(.ant-select-disabled)
    .ant-select-selector {
    .ant-select-selection-item {
      color: @grey-140;
    }
  }

  .ant-pagination-options .ant-pagination-options-quick-jumper {
    font-weight: bold;

    input {
      background: @white;
      border: 1px solid @grey-100;
      border-radius: 20px;
    }
  }
}
