@import "./_variables.less";
@import "./Theme.less";

@gap_section_mobile: 10px;

.text-right {
  text-align: right !important;
}

.full-height {
  height: 100%;
}

.wrapper-inner-block {
  background: transparent;
}

.row-block {
  padding: 0;
  overflow: visible;

  @media screen and (max-width: @breakpoint-xxs) {
    padding: @gap_section_mobile 15px 0;
  }
}

.custom-text-color {
  strong {
    color: @grey-180;
  }
}

.warning-icon {
  color: @orange-100;
}

.date-time {
  font-size: 12px;
  color: @grey-120;
  padding: 5px;
  text-align: right;
}

.info-icon {
  color: @purple-100;
}

.rating-font-weight {
  font-weight: 900 !important;
}

.dollar-addon {
  .ant-input-number-group-addon {
    width: 38px;
  }
}

.required-star {
  color: @red-100;
}

.icon-colour {
  color: @grey-120;
}

.selector {
  max-height: 300px;
  overflow-x: auto;
}

.widget-padding {
  padding: @md-spacing;
}

@media screen and (max-width: @breakpoint-xxs) {
  .ant-select-dropdown {
    max-width: 270px !important;
  }
}
