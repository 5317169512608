@import "../../Theme";
@import "../../_variables";

.core-switch {
  .ant-switch {
    background-color: @grey-100;
    height: 22px;
    outline: 1px solid @white;
    transition: 0.3s;

    &:hover {
      background-color: @grey-120;
      transition: 0.3s;
    }

    &.ant-switch-small {
      height: 16px;
      .ant-switch-handle:not(.ant-switch-checked) {
        width: 12px;
        height: 12px;
      }
      &.ant-switch-checked .ant-switch-handle {
        width: 12px;
        height: 12px;
        left: calc(100% - 12px - 2px);
      }
    }
  }
  .ant-switch-handle:not(.ant-switch-checked) {
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
  }
  .ant-switch-checked {
    background-color: @green-100;
    transition: 0.3s;
    .ant-switch-handle {
      left: calc(100% - 18px - 2px);
    }
    &:hover {
      background-color: @green-120;
      transition: 0.3s;
    }
  }
}
