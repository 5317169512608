@import "../../_variables.less";
@import "../../Theme.less";

.core-checkbox {
  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: @green-40;
      }
    }
    .ant-checkbox {
      &.ant-checkbox-checked:not(.ant-checkbox-disabled) {
        .ant-checkbox-inner {
          border-color: @green-40;
        }
        &:after {
          border: 1px solid @green-40;
        }
      }
    }
    :not(.ant-checkbox-checked) {
      .ant-checkbox-input {
        &:focus + .ant-checkbox-inner {
          border-color: @green-40;
        }
      }
    }
  }
  .ant-checkbox-wrapper-disabled {
    .ant-checkbox-disabled .ant-checkbox-inner {
      background-color: @grey-20;
      border-color: @grey-60 !important;
    }

    .ant-typography {
      color: @grey-80;
    }
  }
}
