@import "../../_variables.less";
@import "../../Theme.less";

.alert-icon-padding-top {
  padding-top: 4px;
}

.notification-default-block {
  .ant-alert {
    padding: 24px;
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }

  .ant-alert-message {
    font-size: 20px;
    font-weight: bold;
  }

  .ant-alert-content {
    padding-left: 8px;
  }

  .ant-alert-close-icon {
    color: @grey-140 !important;
  }

  @media screen and (max-width: @breakpoint-xxs) {
    .ant-alert {
      padding: 10px 8px 16px 16px;
    }
  }

  &.ant-notification-notice {
    background: @grey-10;
    border: 1px solid @grey-100;
    color: @grey-160;
    border-radius: 16px;
    padding: 18px 16px;
    margin-bottom: 4px;

    .ant-notification-notice-close {
      right: 16px;
    }

    .ant-notification-notice-content {
      .ant-notification-notice-with-icon {
        .ant-notification-notice-message {
          margin-left: 36px;
        }

        .ant-notification-notice-description {
          margin-left: 36px;
        }
      }
    }
  }

  &.default {
    .ant-alert-default {
      background: @grey-10;
      border: 1px solid @grey-100;
      color: @grey-160;
      border-radius: 16px;
    }

    .anticon {
      color: @grey-140;
    }

    .ant-alert-message {
      h5 {
        color: @grey-160;
      }
    }

    &.ant-notification-notice {
      background: @grey-10;
      border: 1px solid @grey-100;
      color: @grey-160;
      border-radius: 16px;
    }
  }

  &.info {
    .ant-alert-info {
      background: @blue-10;
      border: 1px solid @blue-80;
      color: @blue-100;
      border-radius: 16px;
    }

    .ant-alert-message {
      h5 {
        color: @blue-100;
      }
    }

    &.ant-notification-notice {
      background: @blue-10;
      border: 1px solid @blue-80;
      color: @blue-100;
      border-radius: 16px;
    }
  }

  &.warning {
    .ant-alert-warning {
      background: @orange-10;
      border: 1px solid @orange-80;
      color: @orange-100;
      border-radius: 16px;
    }

    .ant-alert-message {
      h5 {
        color: @orange-100;
      }
    }

    &.ant-notification-notice {
      background: @orange-10;
      border: 1px solid @orange-80;
      color: @orange-100;
      border-radius: 16px;
    }
  }

  &.error {
    .ant-alert-error {
      background: @red-10;
      border: 1px solid @red-80;
      color: @red-100;
      border-radius: 16px;
    }

    .ant-alert-message {
      h5 {
        color: @red-100;
      }
    }

    &.ant-notification-notice {
      background: @red-10;
      border: 1px solid @red-80;
      color: @red-100;
      border-radius: 16px;
    }
  }

  &.success {
    .ant-alert-success {
      background: @lime-10;
      border: 1px solid @lime-80;
      color: @lime-100;
      border-radius: 16px;
    }

    .ant-alert-message {
      h5 {
        color: @lime-100;
      }
    }

    &.ant-notification-notice {
      background: @lime-10;
      border: 1px solid @lime-80;
      color: @lime-100;
      border-radius: 16px;
    }
  }
}

.children-block {
  margin-top: 16px;
}

.notification-default-block {
  .notification-example {
    padding: 18px 16px;
    margin-bottom: 4px;
  }
}
