@import "../../_variables.less";

.widget-panel {
  padding: @md-spacing;
  height: 100%;
  display: flex;
  flex-direction: column;

  .widget-children {
    margin-top: 14px;
  }

  .widget-title {
    margin-bottom: @xxs-spacing;
  }

  .widget-progress {
    margin-bottom: @xs-spacing;
  }
}
