@import "../../_variables";

.core-typography-input {
  display: inline-block;
  .default {
    font-family: @body-typeface;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: @no-spacing;
  }

  .label {
    font-family: @title-typeface;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: @tight;
  }

  .menu {
    font-family: @title-typeface;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: @tight;
  }
}
