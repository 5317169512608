@import "../../Theme";
@import "../../_variables";

.display-ib {
  display: inline-block;
}

.core-datepicker {
  .set-width();

  .input-width-full {
    width: 100%;
  }

  .ant-picker-input > input {
    font-family: @body-typeface;
    font-size: 14px;
    color: @grey-140;

    &::placeholder {
      color: @grey-100;
      opacity: 1;
    }
  }

  .ant-picker-range-separator {
    color: @grey-100;
  }

  .ant-picker-suffix {
    color: @grey-100;
  }

  .ant-picker-clear {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .solid-suffix-icon {
    display: none;
  }

  .ant-picker {
    font-size: 14px;
    border-color: @grey-100;
    border-radius: 20px;
    padding-top: 4px;
    padding-bottom: 4px;

    &.ant-picker-small {
      padding-top: 1px;
      padding-bottom: 1px;
    }

    &.ant-picker-large {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    &:not(.ant-picker-disabled) {
      &:hover,
      &:focus,
      &.ant-picker-focused {
        border-color: @green-100;

        .ant-picker-suffix {
          color: @green-100;
        }

        .default-suffix-icon {
          display: none;
        }

        .solid-suffix-icon {
          display: inline;
        }
      }
    }

    &.has-value {
      border-color: @grey-120;

      .default-suffix-icon {
        display: none;
      }

      .solid-suffix-icon {
        display: inline;
      }
    }

    &.ant-picker-disabled {
      border-color: @grey-100;
      background: @grey-40;
      opacity: 0.6;

      .ant-picker-input > input {
        color: @grey-100;
      }
    }
  }
}

.core-datepicker-dropdown {
  .ant-picker-panel-container {
    border: 1px solid @grey-100;
    border-radius: 16px;
    box-shadow: 0 2px 30px 2px rgba(0, 0, 0, 0.05);
  }

  .ant-picker-header-view {
    font-size: 14px;
    font-weight: bold;

    button {
      text-transform: uppercase;
    }
  }

  .ant-picker-footer {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .ant-picker-today-btn,
  .ant-picker-now-btn {
    color: @green-100;
    letter-spacing: @x-tight;
  }

  .ant-picker-ok {
    .ant-btn {
      height: 26px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 20px;
      padding-left: 12px;
      padding-right: 12px;

      &:not[disabled] {
        background: @green-100;
        border-color: @green-100;
      }
    }
  }

  .ant-picker-month-panel {
    .ant-picker-cell-in-view.ant-picker-cell-selected,
    .ant-picker-cell-in-view.ant-picker-cell-range-start,
    .ant-picker-cell-in-view.ant-picker-cell-range-end {
      .ant-picker-cell-inner {
        color: rgba(0, 0, 0, 0.85);
        border: 1px solid @green-100;
        background: transparent;
      }
    }
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    font-weight: bold;
    color: @white;
    background: @green-100;
  }

  .ant-picker-footer .ant-btn-primary[disabled] {
    color: @white;
    border-color: @green-100;
    background: @green-100;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border-color: @green-100;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: @green-100;
  }
}
