@import "../../Theme.less";
@import "../../_variables.less";

.core-menu-container {
  .ant-menu-submenu-title .anticon.ant-menu-submenu-expand-icon {
    font-size: 16px;
  }

  .core-menu {
    .ant-menu-item {
      display: flex;
      align-items: center;

      .ant-menu-title-content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &.ant-dropdown-menu-horizontal,
    &.ant-menu-horizontal {
      border-color: @grey-60;
      border-style: none solid solid solid;
      border-width: 1px;
      border-bottom-left-radius: @radius-sm;
      border-bottom-right-radius: @radius-sm;

      .core-menu-submenu:not(.ant-menu-overflow-item-rest),
      .core-menu-item:not(.ant-menu-overflow-item-rest) {
        &.ant-dropdown-menu-item,
        &.ant-dropdown-menu-submenu,
        &.ant-menu-item,
        &.ant-menu-submenu {
          .ant-typography.default {
            color: @grey-140;
          }

          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          margin: 0;
          order: unset !important;
          height: 48px;
          top: 0;

          .ant-dropdown-menu-submenu-expand-icon,
          .ant-dropdown-menu-submenu-arrow,
          .ant-menu-submenu-expand-icon,
          .ant-menu-submenu-arrow {
            color: @grey-100;
            position: unset;
            top: unset;
            right: unset;
            width: unset;
            transform: unset;
          }

          &:after {
            border-bottom: 0;
          }

          &:not(.ant-dropdown-menu-item-disabled):not(.ant-dropdown-menu-submenu-disabled):not(.ant-menu-item-disabled):not(.ant-menu-submenu-disabled):hover {
            background-color: @green-100;

            .ant-dropdown-menu-title-content,
            .ant-menu-title-content {
              .ant-typography.default {
                color: @white;
              }
            }

            .ant-dropdown-menu-item-icon,
            .ant-menu-item-icon {
              color: @white;
            }

            .icon-right {
              .anticon {
                color: @white;
              }
            }

            &:after {
              border-bottom: 0;
            }

            .horizontal-expand-icon {
              color: @white;
            }
          }

          .horizontal-expand-icon {
            color: @grey-100;
            margin-left: 10px;
          }

          &.ant-dropdown-menu-item-disabled,
          &.ant-dropdown-menu-submenu-disabled,
          &.ant-menu-item-disabled,
          &.ant-menu-submenu-disabled {
            background: @grey-40;
            opacity: 0.5 !important;

            .ant-typography.default {
              color: @grey-100;
            }
          }
        }

        &.ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-disabled),
        &.ant-dropdown-menu-submenu-selected:not(.ant-dropdown-menu-submenu-disabled),
        &.ant-menu-item-selected:not(.ant-menu-item-disabled),
        &.ant-menu-submenu-selected:not(.ant-menu-submenu-disabled) {
          .ant-typography.default {
            color: @green-100;
          }

          box-shadow: inset 0 -2px 0 @green-100;

          .ant-dropdown-menu-title-content,
          .ant-menu-title-content {
            .ant-typography.default {
              font-weight: 600;
            }
          }

          .horizontal-expand-icon {
            color: @green-100;
          }

          &:after {
            border-bottom: 0;
          }

          .ant-dropdown-menu-item-icon,
          .ant-menu-item-icon {
            color: @green-100;
          }

          .icon-right {
            .anticon {
              color: @green-100;
            }
          }
        }

        .ant-dropdown-menu-item-icon,
        .ant-menu-item-icon {
          color: @grey-100;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
        }

        .icon-right {
          .anticon {
            font-size: 14px;
            color: @grey-100;
            font-weight: 400;
            font-style: normal;
            margin-left: 10px;
          }
        }
      }

      .ant-menu-overflow-item-rest {
        color: @green-100;
        line-height: 48px;
        box-shadow: none;

        &:hover {
          box-shadow: none;
        }

        &.ant-menu-submenu-selected {
          box-shadow: inset 0 -2px 0 @green-100;
        }
      }
    }

    &.ant-dropdown-menu-vertical,
    &.ant-menu-vertical {
      border-right: 0;

      .core-menu-item {
        &.ant-dropdown-menu-item,
        &.ant-menu-item {
          .ant-typography.default {
            color: @grey-140;
          }

          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          order: unset;
          border-radius: 4px;
          margin: 0;
          height: 38px;

          &.ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-disabled),
          &.ant-menu-item-selected:not(.ant-menu-item-disabled) {
            .ant-typography.default {
              color: @grey-140;
            }
            border-left: 4px @green-100 solid;
            background-color: @green-10;

            .ant-dropdown-menu-title-content,
            .ant-menu-title-content {
              .ant-typography.default {
                font-weight: 600;
              }
            }

            .ant-dropdown-menu-item-icon,
            .ant-menu-item-icon {
              color: @grey-100;
            }
          }

          &:not(.ant-dropdown-menu-item-disabled):not(.ant-dropdown-menu-submenu-disabled):not(.ant-menu-item-disabled):not(.ant-menu-submenu-disabled):hover {
            .ant-dropdown-menu-item-icon,
            .ant-menu-item-icon {
              color: @white;
              font-weight: 600;
            }

            .ant-dropdown-menu-submenu-expand-icon,
            .ant-menu-submenu-expand-icon {
              color: @white;
              font-weight: 600;
            }

            background-color: @green-100;

            .ant-typography.default {
              color: @white;
              font-weight: 600;

              .ant-typography.tooltip {
                color: @white;
                font-weight: 600;
              }
            }
          }

          &.ant-dropdown-menu-item-disabled,
          &.ant-menu-item-disabled {
            background: @grey-40;
            opacity: 0.5;

            .ant-typography.default {
              color: @grey-100;
            }
          }
        }

        .ant-dropdown-menu-item-icon,
        .ant-menu-item-icon {
          color: @grey-100;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
        }
      }

      .core-menu-submenu {
        &.ant-dropdown-menu-submenu,
        &.ant-menu-submenu {
          .ant-typography.default {
            color: @grey-140;
          }

          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          margin: 0;
          order: unset;

          &.ant-dropdown-menu-submenu-selected:not(.ant-dropdown-menu-submenu-disabled),
          &.ant-menu-submenu-selected:not(.ant-menu-submenu-disabled) {
            .ant-dropdown-menu-submenu-title,
            .ant-menu-submenu-title {
              .ant-typography.default {
                color: @grey-140;
              }

              background-color: @green-10;

              .ant-dropdown-menu-title-content,
              .ant-menu-title-content {
                .ant-typography.default {
                  font-weight: 600;
                }
              }

              .ant-dropdown-menu-item-icon,
              .ant-menu-item-icon {
                color: @grey-100;
              }
            }
          }

          .ant-dropdown-menu-submenu-title,
          .ant-menu-submenu-title {
            border-radius: 4px;
            margin: 0;
            height: 38px;
          }

          .ant-dropdown-menu-submenu-expand-icon,
          .ant-dropdown-menu-submenu-arrow,
          .ant-menu-submenu-expand-icon,
          .ant-menu-submenu-arrow {
            color: @grey-100;

            .expand-icon {
              display: unset;
            }
          }

          &:after {
            border-bottom: 0;
          }

          &:not(.ant-dropdown-menu-item-disabled):not(.ant-dropdown-menu-submenu-disabled):not(.ant-menu-item-disabled):not(.ant-menu-submenu-disabled):hover {
            .ant-dropdown-menu-submenu-title,
            .ant-menu-submenu-title {
              .ant-dropdown-menu-item-icon,
              .ant-menu-item-icon {
                color: @white;
                font-weight: 600;
              }

              .ant-dropdown-menu-submenu-expand-icon,
              .ant-menu-submenu-expand-icon {
                color: @white;
                font-weight: 600;
              }

              background-color: @green-100;

              .ant-typography.default {
                color: @white;
                font-weight: 600;
              }
            }

            &:after {
              border-bottom: 0;
            }
          }

          &.ant-dropdown-menu-submenu-disabled,
          &.ant-menu-submenu-disabled {
            .ant-dropdown-menu-submenu-title,
            .ant-menu-submenu-title {
              background: @grey-40;
              opacity: 0.5;

              .ant-typography.default {
                color: @grey-100;
              }
            }
          }

          .expand-icon {
            display: none;
            position: absolute;
            top: 50%;
            right: 0px;
            width: 10px;
            transform: translateY(-50%);
          }
        }

        .ant-dropdown-menu-item-icon,
        .ant-menu-item-icon {
          color: @grey-100;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
        }
      }
    }
  }

  .hide-menu-horizontal-border {
    &.ant-menu-horizontal {
      border: none;
    }
  }
}

.ant-menu-submenu-popup,
.panel-dropdown-popup.ant-dropdown {
  border: 1px solid @grey-100;
  border-radius: 14px;
  padding: 2px;
  background-color: @white;
  box-shadow: 0px 5px 16px 2px rgba(0, 0, 0, 0.22);

  .horizontal-expand-icon {
    display: none;
  }

  .ant-dropdown-menu,
  .ant-dropdown-menu-root,
  .ant-menu-sub {
    box-shadow: none;
    border-radius: 11px;

    .ant-dropdown-menu-submenu,
    .ant-menu-item,
    .ant-menu-submenu {
      &:first-child {
        border-radius: 11px 11px 4px 4px;
        margin: 0 0 2px 0;
      }

      &:last-child {
        border-radius: 4px 4px 11px 11px;
        margin: 2px 0 0 0;
      }

      &:first-child:nth-last-child(1) {
        border-radius: 11px;
        margin: 0;
      }
    }
  }

  .ant-dropdown-menu {
    padding: 0px;
  }

  .icon-right {
    margin-left: 10px;

    .anticon {
      color: @grey-100;
      font-size: 14px;
    }
  }

  .core-menu-item {
    &.ant-menu-item {
      margin: 2px 0;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
    }

    .ant-menu-item-icon {
      color: @grey-100;
      font-size: 14px;
    }

    &.ant-dropdown-menu-item,
    &.ant-menu-item {
      .ant-typography.default {
        color: @grey-140;
      }

      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      margin: 2px 0 2px 0;
      border-radius: 4px;

      &:hover {
        color: @white;
        font-weight: 600;
      }

      &:first-child {
        border-radius: 11px 11px 4px 4px;
        margin: 0 0 2px 0;
      }

      &:last-child {
        border-radius: 4px 4px 11px 11px;
        margin: 2px 0 0 0;
      }

      &:first-child:nth-last-child(1) {
        border-radius: 11px;
        margin: 0;
      }

      &.ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-disabled),
      &.ant-menu-item-selected:not(.ant-menu-item-disabled) {
        background-color: @green-10;

        .ant-typography.default {
          color: @grey-140;
        }

        .ant-dropdown-menu-title-content,
        .ant-menu-title-content {
          .ant-typography.default {
            font-weight: 600;
          }
        }
      }

      &:not(.ant-dropdown-menu-item-disabled):not(.ant-menu-item-disabled):hover {
        background-color: @green-100;

        .ant-typography.default {
          color: @white;
          font-weight: 600;
        }

        .ant-menu-item-icon {
          color: @white;
          font-weight: 600;
        }

        .icon-right {
          .anticon {
            color: @white;
            font-weight: 600;
          }
        }
      }

      &.ant-dropdown-menu-item-disabled,
      &.ant-menu-item-disabled {
        background: @grey-40;
        opacity: 0.5;

        .ant-typography.default {
          color: @grey-100;
        }
      }
    }
  }

  .core-menu-submenu {
    .ant-menu-item-icon {
      color: @grey-100;
      font-size: 14px;
    }

    &.ant-dropdown-menu-submenu,
    &.ant-menu-submenu {
      margin: 2px 0;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;

      .ant-dropdown-menu-submenu-title,
      .ant-menu-submenu-title {
        margin: 0 0 2px 0;
      }

      .ant-dropdown-menu-submenu-expand-icon,
      .ant-menu-submenu-expand-icon {
        color: @grey-100;
        font-weight: 600;
      }

      .ant-typography.default {
        color: @grey-140;
      }

      &.ant-menu-submenu-selected:not(.ant-menu-submenu-disabled) {
        background-color: @green-10;

        .ant-typography.default {
          color: @grey-140;
        }

        .ant-menu-title-content {
          .ant-typography.default {
            font-weight: 600;
          }
        }
      }

      &:not(.ant-menu-submenu-disabled):hover {
        background-color: @green-100;

        .ant-typography.default {
          color: @white;
          font-weight: 600;
        }

        .ant-menu-submenu-expand-icon {
          color: @white;
          font-weight: 600;
        }

        .ant-menu-item-icon {
          color: @white;
          font-weight: 600;
        }

        .icon-right {
          .anticon {
            color: @white;
            font-weight: 600;
          }
        }
      }

      &.ant-menu-submenu-disabled {
        background: @grey-40;
        opacity: 0.5;

        .ant-typography.default {
          color: @grey-100;
        }

        .anticon {
          color: @grey-100;
        }

        :hover {
          background: @grey-40;
        }
      }

      &.ant-menu-submenu-open:not(.ant-menu-submenu-disabled) {
        .ant-typography.default {
          color: @grey-140;
        }
      }

      &.ant-dropdown-menu-submenu-vertical,
      &.ant-menu-submenu-vertical {
        &.ant-dropdown-menu-submenu-selected:not(.ant-dropdown-menu-submenu-disabled):hover,
        &.ant-menu-submenu-selected:not(.ant-menu-submenu-disabled):hover {
          .expand-icon {
            color: @grey-100;
          }
        }
      }
    }
  }

  .core-menu-item-group {
    &.ant-dropdown-menu-item-group {
      .ant-dropdown-menu-item,
      .ant-menu-item {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        margin: 2px 0 2px 0;
        border-radius: 4px;
        padding: 5px 16px;
      }

      &:last-child {
        .ant-dropdown-menu-item,
        .ant-menu-item {
          &:last-child {
            border-radius: 4px 4px 11px 11px;
            margin: 2px 0 0 0;
          }
        }
      }

      .ant-dropdown-menu-item-group-title {
        padding: 9px 12px;
        border-bottom: 1px solid @grey-80;
      }

      .ant-dropdown-menu-item-group-list {
        margin: 2px 0;
      }
    }
  }

  &.popup-horizontal {
    &.ant-dropdown-menu-submenu-placement-topLeft,
    &.ant-dropdown-menu-submenu-placement-bottomLeft,
    &.ant-menu-submenu-placement-topLeft,
    &.ant-menu-submenu-placement-bottomLeft {
      border-radius: 0 0 14px 14px;

      .ant-dropdown-menu-vertical,
      .ant-menu-vertical {
        border-radius: 4px 4px 11px 11px;
      }

      .ant-dropdown-menu-item,
      .ant-menu-item {
        &:first-child {
          border-radius: 4px;
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
        }

        &:first-child:nth-last-child(1) {
          border-radius: 4px 4px 11px 11px;
        }
      }

      .ant-dropdown-menu-submenu-vertical,
      .ant-menu-submenu-vertical {
        border-radius: 4px;

        &:first-child {
          border-radius: 4px;
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
        }

        &:first-child:nth-last-child(1) {
          border-radius: 4px 4px 11px 11px;
        }
      }

      .horizontal-expand-icon {
        display: none;
      }
    }

    &.ant-dropdown-menu-submenu-placement-leftTop,
    &.ant-dropdown-menu-submenu-placement-rightTop,
    &.ant-menu-submenu-placement-leftTop,
    &.ant-menu-submenu-placement-rightTop {
      border-radius: 14px;

      .ant-dropdown-menu-vertical,
      .ant-menu-vertical {
        border-radius: 11px;
      }

      .ant-dropdown-menu-item,
      .ant-menu-item {
        border-radius: 4px;

        &:first-child {
          border-radius: 11px 11px 4px 4px;
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
        }

        &:first-child:nth-last-child(1) {
          border-radius: 11px;
        }
      }

      .ant-dropdown-submenu-vertical,
      .ant-menu-submenu-vertical {
        &:first-child {
          border-radius: 11px 11px 4px 4px;
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
        }

        &:first-child:nth-last-child(1) {
          border-radius: 11px;
        }
      }

      .horizontal-expand-icon {
        display: none;
      }
    }
  }
}

.core-menu-popup-container {
  z-index: 1300;

  &.ant-menu-submenu-popup,
  &.panel-dropdown-popup.ant-dropdown {
    border: 1px solid @grey-100;
    border-radius: 14px;
    padding: 2px;
    background-color: @white;
    box-shadow: 0px 5px 16px 2px rgba(0, 0, 0, 0.22);

    .horizontal-expand-icon {
      display: none;
    }

    .ant-menu-item-icon {
      color: @grey-100;
      font-size: 14px;
    }

    .ant-dropdown-menu,
    .ant-dropdown-menu-root,
    .ant-menu-sub {
      box-shadow: none;
      border-radius: 11px;

      .ant-dropdown-menu-submenu,
      .ant-menu-item,
      .ant-menu-submenu {
        margin: 2px 0;

        .ant-typography.default {
          color: @grey-140;
        }

        font-size: 14px;
        font-weight: 400;
        font-style: normal;

        &:first-child {
          border-radius: 11px 11px 4px 4px;
          margin: 0 0 2px 0;

          &.ant-dropdown-menu-submenu-vertical
            .ant-dropdown-menu-submenu-title {
            border-radius: 11px 11px 4px 4px;
            margin: 0 0 2px 0;
          }
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
          margin: 2px 0 0 0;

          &.ant-dropdown-menu-submenu-vertical
            .ant-dropdown-menu-submenu-title {
            border-radius: 4px 4px 11px 11px;
            margin: 2px 0 0 0;
          }
        }

        &:first-child:nth-last-child(1) {
          border-radius: 11px;
          margin: 0;

          &.ant-dropdown-menu-submenu-vertical
            .ant-dropdown-menu-submenu-title {
            border-radius: 11px;
            margin: 0;
          }
        }

        &:not(.ant-menu-item-disabled):not(.ant-menu-submenu-disabled):hover {
          background-color: @green-100;

          .ant-typography.default {
            color: @white;
            font-weight: 600;
          }

          .ant-menu-submenu-expand-icon {
            color: @white;
            font-weight: 600;
          }

          .ant-menu-item-icon {
            color: @white;
            font-weight: 600;
          }

          .icon-right {
            .anticon {
              color: @white;
              font-weight: 600;
            }
          }

          .ant-dropdown-menu-submenu-title,
          .ant-menu-submenu-title {
            .ant-typography.default {
              color: @white;
              font-weight: 600;
            }
          }
        }

        &.ant-menu-submenu-disabled {
          background: @grey-40;
          opacity: 0.5;

          .ant-typography.default {
            color: @grey-100;
          }
        }

        &.ant-menu-submenu-selected:not(.ant-menu-submenu-disabled) {
          background-color: @green-10;

          .ant-typography.default {
            color: @grey-140;
          }

          .ant-menu-title-content {
            .ant-typography.default {
              font-weight: 600;
            }
          }
        }

        &.ant-menu-submenu-open:not(.ant-menu-submenu-disabled) {
          .ant-typography.default {
            color: @grey-140;
          }
        }

        .ant-dropdown-menu-submenu-title,
        .ant-menu-submenu-title {
          margin: 0 0 2px 0;
          border-radius: 4px;
        }

        .ant-menu-submenu-expand-icon {
          color: @grey-100;
        }
      }
    }

    .ant-dropdown-menu {
      padding: 0px;
    }

    .icon-right {
      margin-left: 10px;

      .anticon {
        color: @grey-100;
        font-size: 14px;
      }
    }

    .core-menu-item {
      &.ant-dropdown-menu-item,
      &.ant-menu-item {
        .ant-typography.default {
          color: @grey-140;
        }

        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        margin: 2px 0 2px 0;
        border-radius: 4px;

        &:hover {
          color: @white;
          font-weight: 600;
        }

        &:first-child {
          border-radius: 11px 11px 4px 4px;
          margin: 0 0 2px 0;
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
          margin: 2px 0 0 0;
        }

        &:first-child:nth-last-child(1) {
          border-radius: 11px;
          margin: 0;
        }

        &.ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-disabled),
        &.ant-menu-item-selected:not(.ant-menu-item-disabled) {
          background-color: @green-10;

          .ant-typography.default {
            color: @grey-140;
          }

          .ant-dropdown-menu-title-content,
          .ant-menu-title-content {
            .ant-typography.default {
              font-weight: 600;
            }
          }
        }

        &:not(.ant-dropdown-menu-item-disabled):not(.ant-menu-item-disabled):hover {
          background-color: @green-100;

          .ant-typography.default {
            color: @white;
            font-weight: 600;
          }
        }

        &.ant-dropdown-menu-item-disabled,
        &.ant-menu-item-disabled {
          background: @grey-40;
          opacity: 0.5;

          .ant-typography.default {
            color: @grey-100;
          }
        }
      }
    }

    .core-menu-submenu {
      .ant-dropdown-menu-item-icon,
      .ant-menu-item-icon {
        .expand-icon {
          color: @lime-100;
        }
      }

      &.ant-dropdown-menu-submenu,
      &.ant-menu-submenu {
        &.ant-dropdown-menu-submenu-vertical,
        &.ant-menu-submenu-vertical {
          &.ant-dropdown-menu-submenu-selected:not(.ant-dropdown-menu-submenu-disabled):hover,
          &.ant-menu-submenu-selected:not(.ant-menu-submenu-disabled):hover {
            .expand-icon {
              color: @grey-100;
            }
          }
        }
      }
    }

    .core-menu-item-group {
      &.ant-dropdown-menu-item-group {
        .ant-dropdown-menu-item,
        .ant-menu-item {
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          margin: 2px 0 2px 0;
          border-radius: 4px;
          padding: 5px 16px;
        }

        &:last-child {
          .ant-dropdown-menu-item,
          .ant-menu-item {
            &:last-child {
              border-radius: 4px 4px 11px 11px;
              margin: 2px 0 0 0;
            }
          }
        }

        .ant-dropdown-menu-item-group-title {
          padding: 9px 12px;
          border-bottom: 1px solid @grey-80;
        }

        .ant-dropdown-menu-item-group-list {
          margin: 2px 0;
        }
      }
    }

    &.popup-horizontal {
      &.ant-dropdown-menu-submenu-placement-topLeft,
      &.ant-dropdown-menu-submenu-placement-bottomLeft,
      &.ant-menu-submenu-placement-topLeft,
      &.ant-menu-submenu-placement-bottomLeft {
        border-radius: 0 0 14px 14px;

        .ant-dropdown-menu-vertical,
        .ant-menu-vertical {
          border-radius: 4px 4px 11px 11px;
        }

        .ant-dropdown-menu-item,
        .ant-menu-item {
          &:first-child {
            border-radius: 4px;
          }

          &:last-child {
            border-radius: 4px 4px 11px 11px;
          }

          &:first-child:nth-last-child(1) {
            border-radius: 4px 4px 11px 11px;
          }
        }

        .ant-dropdown-menu-submenu-vertical,
        .ant-menu-submenu-vertical {
          border-radius: 4px;

          &:first-child {
            border-radius: 4px;
          }

          &:last-child {
            border-radius: 4px 4px 11px 11px;
          }

          &:first-child:nth-last-child(1) {
            border-radius: 4px 4px 11px 11px;
          }
        }

        .horizontal-expand-icon {
          display: none;
        }
      }

      &.ant-dropdown-menu-submenu-placement-leftTop,
      &.ant-dropdown-menu-submenu-placement-rightTop,
      &.ant-menu-submenu-placement-leftTop,
      &.ant-menu-submenu-placement-rightTop {
        border-radius: 14px;

        .ant-dropdown-menu-vertical,
        .ant-menu-vertical {
          border-radius: 11px;
        }

        .ant-dropdown-menu-item,
        .ant-menu-item {
          border-radius: 4px;

          &:first-child {
            border-radius: 11px 11px 4px 4px;
          }

          &:last-child {
            border-radius: 4px 4px 11px 11px;
          }

          &:first-child:nth-last-child(1) {
            border-radius: 11px;
          }
        }

        .ant-dropdown-submenu-vertical,
        .ant-menu-submenu-vertical {
          &:first-child {
            border-radius: 11px 11px 4px 4px;
          }

          &:last-child {
            border-radius: 4px 4px 11px 11px;
          }

          &:first-child:nth-last-child(1) {
            border-radius: 11px;
          }
        }

        .horizontal-expand-icon {
          display: none;
        }
      }
    }
  }

  &.ant-dropdown-menu-submenu-popup {
    min-width: 180px;
    border: 1px solid @grey-100;
    border-radius: 14px;
    padding: 2px;
    background-color: @white;
    box-shadow: 0 5px 16px 2px rgba(0, 0, 0, 0.22);

    .ant-dropdown-menu-sub {
      box-shadow: none;
      border-radius: 11px;
      margin: unset;
      padding: unset;

      .ant-dropdown-menu-item-group {
        .ant-dropdown-menu-item-group-title {
          padding: 8px 12px;
        }
        .ant-dropdown-menu-item-group-list {
          margin: unset;
        }
      }

      .ant-dropdown-menu-item-only-child {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        margin: 2px 0 2px 0;
        padding: 8px 5px 8px 16px;
        border-radius: 4px;

        .ant-typography.default {
          color: @grey-140;
        }

        &:first-child {
          border-radius: 11px 11px 4px 4px;
          margin: 0 0 2px 0;
        }

        &:last-child {
          border-radius: 4px 4px 11px 11px;
          margin: 2px 0 0 0;
        }

        &:first-child:nth-last-child(1) {
          border-radius: 11px;
          margin: 0;
        }

        &:not(.ant-dropdown-menu-item-disabled):hover {
          background-color: @green-100;

          .ant-typography.default {
            color: @white;
            font-weight: 600;
          }
        }

        &.ant-dropdown-menu-item-disabled {
          background: @grey-40;
          opacity: 0.5;

          .ant-typography.default {
            color: @grey-100;
          }
        }

        &.ant-dropdown-menu-item-selected:not(.ant-dropdown-menu-item-disabled) {
          background-color: @green-10;

          .ant-typography.default {
            color: @grey-140;
          }

          .ant-dropdown-menu-title-content {
            .ant-typography.default {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark)
    > .ant-menu-overflow-item-rest:last-child {
    top: 0px;

    .ant-dropdown-menu-submenu-title,
    .ant-menu-submenu-title {
      color: @grey-140;
    }

    &:after {
      border-bottom: 0;
    }

    &.ant-menu-submenu-active {
      box-shadow: 0;

      .ant-dropdown-menu-submenu-title,
      .ant-menu-submenu-title {
        color: @grey-140;
      }
    }

    &.ant-menu-submenu-selected {
      box-shadow: inset 0 -2px 0 @green-100;

      .ant-dropdown-menu-submenu-title,
      .ant-menu-submenu-title {
        color: @green-100;
      }
    }
  }

  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu-submenu-title {
      line-height: 40px;
      height: 40px;
      padding-right: 34px;
      padding-top: 0px;
      padding-bottom: 0px;

      .ant-dropdown-menu-submenu-expand-icon {
        font-size: 12px;
        min-width: 14px;
        right: 16px;
        top: 14px;
        color: @grey-100;
      }
    }
  }
}
