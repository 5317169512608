@import "../../Theme.less";

.core-modal {
  .ant-modal-content {
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05),
      0px 6px 20px rgba(0, 0, 0, 0.08), 0px 4px 10px -4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    border: 1px solid @grey-100;

    .ant-modal-header {
      border-radius: 16px 16px 0 0;
      border-bottom: 1px solid @grey-100;
      .ant-modal-title {
        h3.ant-typography {
          margin-bottom: 0;
          .icon {
            margin-right: 8px;
          }

          .status-icon {
            margin-right: 8px;
          }
        }
      }
    }

    .ant-modal-close-x {
      width: 61px;
      height: 61px;
      font-size: 14px;
      .anticon {
        vertical-align: middle;
      }
    }

    .ant-modal-body {
      padding: 24px 24px 28px 24px;
    }

    .ant-modal-footer {
      text-align: left;
      border-top: 1px solid @grey-80;
      margin: 0 24px 24px 24px;
      padding: 19px 0 0 0;
    }
  }

  &.footer-full-width {
    .ant-modal-footer {
      .ant-space {
        width: 100%;

        .ant-space-item {
          width: 100%;
        }
      }
    }
  }
}

.core-modal.feature-type {
  .ant-modal-content {
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05),
      0px 6px 20px rgba(0, 0, 0, 0.08), 0px 4px 10px -4px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    border: 1px solid @grey-100;

    .ant-modal-header {
      border-radius: 16px 16px 0 0;
      border-bottom: 0;
      padding: 52px 48px 0 48px;

      .ant-modal-title {
        h3.ant-typography {
          margin-bottom: 0;
          text-align: center;
          color: @grey-180;

          .icon {
            font-size: 63px;
            color: @grey-140;
          }

          .status-icon {
            font-size: 63px;
          }
        }
      }
    }

    .ant-modal-close-x {
      width: 61px;
      height: 61px;
      font-size: 14px;
      .anticon {
        vertical-align: middle;
      }
    }

    .ant-modal-body {
      padding: 8px 48px 24px 48px;
      text-align: center;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0;
      padding: 0 48px 48px 48px;
      & > * {
        margin-right: 0;
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }

  &.footer-full-width {
    .ant-modal-footer {
      .ant-space {
        width: 100%;

        .ant-space-item {
          width: 100%;
        }
      }
    }
  }
}
