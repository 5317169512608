@import "../../../global.less";

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  border-radius: 14px;
  border: 1px solid @grey-100;
  padding: 2px;

  .MuiList-root.MuiMenu-list {
    padding: 0;

    .MuiButtonBase-root.MuiMenuItem-root {
      margin-bottom: 2px;
      border-radius: 4px;
      height: 38px;
      border: 0;

      &.Mui-disabled {
        background-color: @grey-40;
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: auto;
      }

      &:hover:not(.Mui-disabled) {
        background-color: @green-100;

        > .MuiBox-root {
          font-weight: 600;
          color: @white;
          > .MuiListItemIcon-root {
            color: @white;
            > svg {
              color: @white;
            }
          }
        }
      }

      &:first-child {
        border-radius: 11px 11px 4px 4px;
      }

      &:last-child {
        border-radius: 4px 4px 11px 11px;
        margin-bottom: 0;
      }

      > .MuiBox-root {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: @grey-160;
        font-family: "Inter", sans-serif;

        > .MuiListItemIcon-root {
          font-size: 20px;
        }
      }
    }
  }
}
