@import "../../Theme";

.core-user-drawer {
  z-index: 2001;

  .ant-drawer-content-wrapper {
    max-width: 320px;
  }

  .ant-drawer-body {
    padding: 12px;
  }

  .ant-drawer-header {
    padding: 14px 16px 12px 16px;
  }

  .ant-drawer-content {
    border-radius: 16px 0 0 16px;
  }

  .divider {
    .ant-divider {
      border-color: @green-100;
      border-width: 2px;
    }
  }

  .ant-menu.ant-menu-vertical.user-drawer-menu {
    .ant-menu-item {
      height: 48px;
      vertical-align: middle;
      padding-top: 4px;
    }
    .ant-menu-item:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  .bottom-container {
    padding: 0px 16px;
  }

  .user-icon {
    color: @white;
    padding: 8px 6px 8px 6px;
    background-color: @grey-100;
    border-radius: 50%;
    font-size: 20px;
    vertical-align: sub;
  }
}
