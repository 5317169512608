@import "../../_variables.less";
@import "../../Theme.less";

.user-panel-section {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;

  .user-full-name-text {
    color: @white;
    padding-right: 10px;

    @media screen and (max-width: @breakpoint-sm) {
      display: none;
    }
  }

  @media screen and (max-width: @breakpoint-sm) {
    position: absolute;
    top: 14px;
  }
}
