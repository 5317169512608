@import "../../Theme.less";
@import "../../_variables.less";

.radio-button {
  .input-radio-full-size {
    display: flex;

    .ant-radio-button-wrapper {
      flex: 1;
      text-align: center;
    }
  }

  .input-radio-small-size {
    display: flex;
    flex-direction: column;

    .ant-radio-button-wrapper {
      flex: 1;
      text-align: center;
    }
  }
  .ant-radio-group {
    &.ant-radio-group-large {
      .ant-radio-button-wrapper {
        height: 38px;
        padding: 7px 22px;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.5;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    &.ant-radio-group-middle {
      .ant-radio-button-wrapper {
        height: 32px;
        padding: 5px 20px;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.5;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    &.ant-radio-group-small {
      .ant-radio-button-wrapper {
        height: 26px;
        padding: 2px 12px;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.5;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      &.ant-radio-button-wrapper:first-child {
        border-right-color: @grey-80;
      }
    }

    .ant-radio-button-wrapper:first-child {
      border-radius: 19px 0px 0px 19px;
    }

    .ant-radio-button-wrapper:last-child {
      border-radius: 0px 19px 19px 0px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
      background: none;
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
      color: @grey-120;
    }

    .ant-radio-button-wrapper {
      font-weight: bold;
    }
  }

  .ant-radio-group-solid {
    .ant-radio-button-wrapper {
      text-transform: uppercase;
    }
    .ant-radio-button-wrapper-checked {
      &:focus-within {
        box-shadow: none;
      }

      border-color: @grey-80;
      background-color: @green-100;

      &:hover {
        background-color: @green-100;
        box-shadow: none;
      }
    }
  }

  .ant-radio-group-outline {
    .ant-radio-button-wrapper {
      text-transform: uppercase;
    }
    .ant-radio-button-wrapper-checked {
      &:focus-within {
        box-shadow: none;
      }

      background-color: @white;
      color: @green-100;
      border-color: @grey-80;

      &:hover {
        background-color: @white;
        color: @green-100;
        box-shadow: none;
      }
    }
  }
}
