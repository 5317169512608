@import "../../Theme";
@import "../../_variables";
.core-empty-block {
  margin-top: 25px;
  margin-bottom: 30px;

  h3 {
    font-weight: bold;
    margin-top: 10px;
  }
}

.no-image {
  .ant-empty-image {
    height: 0;
  }
}
