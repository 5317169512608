@import "../../../global.less";

.MuiTooltip-popper {
  &[data-popper-placement*="bottom"] {
    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
      margin-top: 10px;
      &:after {
        top: 0;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }

  &[data-popper-placement*="top"] {
    .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
      margin-bottom: 10px;
      &:after {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .MuiTooltip-tooltip {
    padding: 12px 16px;
    border-radius: 12px;
    background-color: @white;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: @grey-160;
    font-family: "Inter", sans-serif;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    &:after {
      content: "";
      position: absolute;
      border-width: 5px;
      border-style: solid;
      border-color: @white transparent transparent transparent;
    }
  }
}
