@import "./Theme.less";

@breakpoint-xxs: 575px;
@breakpoint-xs: 767px;
@breakpoint-sm: 991px;
@breakpoint-md: 1199px;
@breakpoint-lg: 1599px;
@breakpoint-xl: 2599px;

// padding and margin spacing variables
@xxs-spacing: 4px;
@xs-spacing: 8px;
@sm-spacing: 12px;
@md-spacing: 16px;
@lg-spacing: 24px;
@xl-spacing: 32px;
@xxl-spacing: 48px;

// border radius variables
@radius-xs: 2px;
@radius-sm: 4px;
@radius-md: 8px;
@radius-lg: 16px;
@radius-xl: 24px;

.responsive-spacing(@spaceSize, @prop) {
  @sm-space: if(
    @spaceSize = @xxs-spacing,
    @xxs-spacing,
    if(
      @spaceSize = @xs-spacing,
      @xxs-spacing,
      if(
        @spaceSize = @sm-spacing,
        @xs-spacing,
        if(
          @spaceSize = @md-spacing,
          @sm-spacing,
          if(@spaceSize = @lg-spacing, @md-spacing, @spaceSize)
        )
      )
    )
  );

  @lg-space: if(@spaceSize = @xl-spacing, @lg-spacing, @spaceSize);

  @media (max-width: @breakpoint-sm) {
    @{prop}: @sm-space;
  }
  @media (min-width: @breakpoint-sm) and (max-width: @breakpoint-lg) {
    @{prop}: @spaceSize;
  }
  @media (min-width: @breakpoint-lg) {
    @{prop}: @lg-space;
  }
}

@gradient: linear-gradient(
  90deg,
  rgba(0, 198, 255, 1) 16.27px,
  rgba(35, 233, 174, 1) 100px
);

@gradientMobile: linear-gradient(
  90deg,
  rgba(0, 198, 255, 1) 8.94px,
  rgba(35, 233, 174, 1) 55px
);

@gradientDev: linear-gradient(90deg, @blue-60 16.27px, @purple-100 100px);

@gradientMobileDev: linear-gradient(90deg, @blue-60 8.94px, @purple-100 55px);

// Color
@light-green: @green-100;
@border-color: @indigo-100;

// Layout
@mobile_sidebar_width: 240px;

.placeholder(@color) {
  &::-webkit-input-placeholder {
    color: @color;
  }

  &::-moz-placeholder {
    color: @color;
  }

  &:-ms-input-placeholder {
    color: @color;
  }

  &::placeholder {
    color: @color;
  }
}

// Typography
@title-typeface: "Inter", sans-serif;
.title-font-family() {
  font-family: @title-typeface;
}

@body-typeface: "Inter", sans-serif;
.body-font-family() {
  font-family: @body-typeface;
}

// Input width
.set-width() {
  @input-base-width: 64px;
  @input-sizes: xs, sm, md, lg, xl, xxl;

  .for-each-loop(@input-sizes, @index: 1)
    when
    (iskeyword(extract(@input-sizes, @index))) {
    @size: extract(@input-sizes, @index);

    .input-width-@{size} {
      width: @input-base-width * @index;
      max-width: 100%;

      &.ant-input-number,
      &.ant-input-affix-wrapper,
      &.ant-input {
        width: @input-base-width * @index;
      }
    }

    .form-item-width-@{size} {
      > .ant-col.ant-form-item-control,
      .ant-form-item-control-input {
        width: 100%;
        max-width: @input-base-width * @index;
      }
    }

    .for-each-loop(@input-sizes, (@index + 1));
  }
  .for-each-loop(@input-sizes);
}
