@import "../../Theme";
@import "../../_variables";

.core-heading {
  font-family: "Inter";

  .align-center {
    text-align: center;
  }

  h1.ant-typography,
  .ant-typography h1 {
    color: @grey-180;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: @tight;
    font-family: @title-typeface;
  }

  h2.ant-typography,
  .ant-typography h2 {
    color: @grey-140;
    font-weight: 600 !important;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: @tight;
    font-family: @title-typeface;
  }

  h3.ant-typography,
  .ant-typography h3 {
    color: @grey-160;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: @tight;
    font-family: @title-typeface;
  }

  h4.ant-typography,
  .ant-typography h4 {
    color: @grey-160;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: @tight;
    font-family: @title-typeface;
  }

  h5.ant-typography,
  .ant-typography h5 {
    color: @grey-160;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: @tight;
    font-family: @title-typeface;
  }

  .ant-typography + h1.ant-typography,
  .ant-typography + h2.ant-typography,
  .ant-typography + h3.ant-typography,
  .ant-typography + h4.ant-typography,
  .ant-typography + h5.ant-typography {
    margin-top: 0;
  }

  div.ant-typography,
  .ant-typography {
    &.no-margin-bottom {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }
  }
}
