@import "../../Theme";
@import "../../_variables";

.chart-title-block {
  text-align: center;
}

.core-chart-legend-fit-parent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .core-chart-legend-layout-core-legend {
    flex: unset;
  }

  .core-chart-legend-layout-core-chart {
    flex: 1;
    width: 100%;
  }
}

.core-chart-fit-parent {
  height: 100%;
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
}

.chart-title-by-file-name-block {
  text-align: center;
  position: absolute;
  top: 50%;
}

.marginRight-6 {
  margin-right: 6px;
}

.core-chart-block {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;

  .g-ytitle,
  .g-y2title {
    .ytitle,
    .y2title {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      letter-spacing: @regular !important;
      color: @grey-160 !important;
      font-family: @body-typeface !important;
    }
  }

  .g-xtitle,
  .g-x2title {
    .xtitle,
    .x2title {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      letter-spacing: @regular !important;
      color: @grey-160 !important;
      font-family: @body-typeface !important;
    }
  }

  .yaxislayer-above > .ytick > text,
  .xaxislayer-above > .xtick > text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: @regular;
    color: @grey-140;
    font-family: @body-typeface;
  }
}

.hide-plotly-tooltips {
  .js-plotly-plot .plotly [data-title] {
    display: none;
  }
}
