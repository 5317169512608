@import "../../Theme";
@import "../../_variables";

.core-body-truncate-with-tooltip.core-tooltip-container {
  position: relative;
}

.core-body {
  word-break: break-word;
  overflow-wrap: break-word;

  &.inline {
    display: inline;
  }

  &.onClick {
    cursor: pointer;
  }

  &.no-text-wrap {
    text-wrap: nowrap;
  }

  &.align-center {
    text-align: center;
  }

  &.ant-typography ul {
    list-style-type: disc;
  }

  &.ant-typography strong {
    font-weight: 600;
  }

  &.p2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: @regular;
    color: @grey-160;
    font-family: @body-typeface;
  }

  &.p1 {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: @regular;
    color: @grey-160;
    font-family: @body-typeface;
  }

  &.p4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: @regular;
    color: @grey-160;
    font-family: @body-typeface;
  }

  &.p3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: @regular;
    color: @grey-160;
    font-family: @body-typeface;
  }

  &.p5 {
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: @regular;
    color: @grey-160;
    font-family: @body-typeface;
  }

  &.ant-typography {
    &.no-margin-bottom {
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.core-body-truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.core-body-hidden-full-content {
  visibility: hidden;
  position: absolute;
}
