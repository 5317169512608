/* call font */
@import (css)
  url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&display=swap&subset=latin");
/* end call font */
@import "Theme";
@import "_variables";

/* global Style */
html,
body {
  height: 100%;
}

/* =======================================
=            Scrollbar style            =
======================================= */

.scrollbars(@size: 16px,
  @border-radius: 0,
  @foreground-color: @grey-80,
  @foreground-hover-color: @grey-120,
  @foreground-active-color: @green-100,
  @background-color: @grey-20,
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: @size;
    height: @size;
    border-radius: @border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background: @foreground-color;
    border-radius: @border-radius;

    &:hover {
      background: @foreground-hover-color;
    }

    &:active {
      background: @foreground-active-color;
    }
  }

  &::-webkit-scrollbar-track {
    background: @background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: @foreground-color @background-color;

  & when (@size < 10px) {
    scrollbar-width: thin;
    scrollbar-color: @foreground-color @background-color;
  }
}

html {
  overflow-x: hidden;

  .scrollbars();

  div {
    .scrollbars(8px, 4px);
  }
}

/* =====  End of Scrollbar style  ====== */

body {
  color: @default-font-color;
  font-family: @default-font;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: @regular;
  text-align: left;
  vertical-align: middle;
  background: @grey-10;

  &.ant-scrolling-effect {
    overflow: visible !important;
    width: 100% !important;
  }
}

textarea,
select,
input,
button {
  outline: none;
}

.container-fluid {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1200px;
  text-align: left;
  margin-top: 20px;
  padding: 50px;
}

img {
  display: inline;
  max-width: 100%;
  height: auto;
  text-align: center;
}

.row {
  margin: 0;
  padding: 0;
}

a {
  color: @default-font-color;
  text-align: left;
}

a:hover {
  text-decoration: none;
}

a:active,
a:focus,
a.active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.no_padding {
  padding: 0;
  margin: 0;
}

.rehub_table .ant-pagination-item-active a {
  text-align: center;
}

/* end global Style */

/* =====================================
=            Global layout            =
===================================== */
.text-center,
.ant-table-thead > tr > th.text-center {
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

@gap_section: 24px;

.main-title-block {
  h1 {
    margin-bottom: @gap_section;
    font-size: 22px;

    @media screen and (max-width: @breakpoint-xxs) {
      margin-bottom: 5px;
    }
  }
}

/* =====  End of Global layout  ====== */

.ant-pagination-item a {
  text-align: center;
}

.ant-alert-message {
  color: @black;
}

.footer-block {
  // position: fixed;
  width: 100%;
  z-index: 10;
}

button,
a {
  &.primary-btn {
    display: inline-block;
    height: auto;
    font-size: 11px;
    font-weight: bold;
    color: @green-100;
    background: @indigo-140;
    line-height: 24px;
    letter-spacing: @x-tight;
    text-transform: uppercase;
    border: 2px solid @green-100;
    border-radius: 18px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 10px;

    &:hover,
    &:focus {
      color: @white;
      background: @black;

      .anticon {
        color: @white;
      }
    }

    .anticon {
      font-size: 14px;
      color: @light-green;
      transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}

a.ant-btn.primary-btn {
  line-height: 24px;
  padding-top: 4.8px;
}

.white-bg {
  background: @white;
}

.main-content-wrapper {
  padding: 30px 40px 60px;

  @media screen and (max-width: @breakpoint-xs) {
    padding: 20px 15px 30px;
  }
}

h2 {
  font-size: 21px;
  font-weight: bold !important;

  &.title-white {
    color: @white;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.table-dark-theme {
  &.sticky {
    .data-grid-container {
      .data-grid {
        tbody {
          tr {
            position: fixed;
            top: 0;
          }
        }
      }
    }

    position: fixed;
    top: 0;
  }

  .ant-pagination-item a,
  .ant-pagination-item-container,
  .ant-pagination-item-link {
    color: @white;
  }

  .ant-pagination-item-ellipsis {
    color: @white !important;
  }

  .ant-pagination-item-active a {
    color: @black;
  }

  .ant-table {
    background: 0;

    thead > tr > th {
      font-size: 14px;
      font-weight: 600;
      color: @white;
      border: 0;

      &:not(.ant-table-column-has-sorters) .ant-table-column-sorter {
        display: none;
      }
    }
  }

  .ant-table table,
  .ant-table thead > tr > th {
    color: @white;
  }

  .ant-table-tbody > tr > td {
    font-size: 12px;
    border: 1px solid @indigo-140;
    border-bottom-width: 2px;
    background: @indigo-140;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: @indigo-140;
  }

  .ant-table-tbody > tr > td:first-child {
    font-weight: bold;
    border-radius: 5px 0 0 5px;
  }

  .ant-table-tbody > tr > td:last-child {
    border-radius: 0 5px 5px 0;
  }

  .ant-table-small {
    border: 0;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: inherit;
  }

  .ant-table-fixed-left table,
  .ant-table-fixed-right table {
    background: @indigo-140;
  }

  .ant-table-fixed-left .ant-table-body-inner {
    margin-right: 0;
    padding-right: 0;
  }

  .green {
    color: @lime-100;
  }

  .red {
    color: @red-100;
  }

  .ant-table-filter-trigger-container {
    right: 10px;
  }

  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    color: rgba(191, 191, 191, 0.6);
  }

  .ant-empty-normal {
    color: rgba(255, 255, 255, 0.75);
  }

  &.grouping-table .ant-table-tbody > tr.ant-table-row-level-0 {
    > td {
      color: @white;
      background: @grey-160;
      text-transform: capitalize;

      .ant-table-row-expand-icon {
        color: @black;
        margin-top: 0;

        &:focus,
        &:hover {
          color: @green-100;
        }
      }
    }

    // Hover
    &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
      td {
        background: @grey-120;
      }
    }
  }
}

.table-light-theme {
  .table-dark-theme;

  .ant-pagination-item a,
  .ant-pagination-item-container,
  .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-pagination-item-ellipsis {
    color: rgba(0, 0, 0, 0.65) !important;
  }

  color: @black;
  border-top: 2px solid @turquoise-100;

  .ant-table-fixed-left table,
  .ant-table-fixed-right table {
    background: @white;
  }

  .ant-table table,
  .ant-table thead > tr > th {
    color: @black;
  }

  .ant-table-container table {
    > thead > tr:first-child th:first-child,
    > tbody > tr:hover {
      background-color: @white;
    }
  }

  .ant-table-tbody > tr > td {
    font-weight: 600;
    color: @grey-180;
    background: @grey-40;
    border: 1px solid @white;
    border-bottom-width: 2px;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: @grey-40;
  }

  .ant-empty-normal {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-table-row-indent {
    min-width: 100px;
  }

  .ant-table-tbody > tr > td:first-child {
    width: 100px;
  }
}

.ant-spin-blur .datasheet-light-theme {
  min-height: 400px;
}

.datasheet-light-theme {
  &:not(.datasheet-grouping) {
    border-top: 2px solid @turquoise-100;
  }

  .data-grid-container .data-grid {
    .cell {
      height: 44px;
      font-size: 12px;
      font-weight: 600;
      color: @black;
      background: @grey-40;
      outline: 1px solid @white;
      border: 0;
      vertical-align: middle;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &.selected {
        outline: 2px double rgb(33, 133, 208);
        border: 0;
        //max-width: 50px;
      }

      &.read-only {
        color: @black;
        background: @grey-40;
      }

      &.text-center {
        text-align: center;

        .data-editor {
          text-align: center;
        }
      }

      &.text-right {
        text-align: right;

        .data-editor {
          text-align: right;
        }
      }

      > input {
        border: 0;
      }
    }

    sticky .flat-cell,
    .flat-cell {
      padding: 1px !important;
      height: 100%;
      line-height: 24px;
      text-align: center !important;

      .normal-cell {
        padding: 8px;
      }
    }

    .text-center {
      text-align: center !important;
    }
  }
}

.datasheet-dark-theme {
  .datasheet-light-theme;

  .data-grid-container .data-grid {
    .cell {
      color: @white;
      background: @indigo-140;
    }

    tr:first-child {
      .cell {
        &.read-only {
          color: @black;
        }
      }
    }
  }
}

.datasheet-undo-block {
  margin: -10px 0 15px 0;
}

.grouping-table {
  .ant-table-row-indent {
    display: none;
  }
}

.datasheet-group-collapse {
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-weight: bold;
    color: @white;
    text-transform: capitalize;
    border-radius: 5px;
    background: @grey-160;
    padding: 8px 8px 8px 32px;
    margin-bottom: 2px;

    .ant-collapse-arrow {
      color: rgba(0, 0, 0, 0.85);
      left: 8px;

      &:hover {
        color: @green-100;
      }
    }
  }

  &.ant-collapse-borderless > .ant-collapse-item {
    border: 0;

    &:last-child .ant-collapse-header {
      border-radius: 5px;
    }

    > .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 0;
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .expand-icon {
    font-size: 18px;
  }

  .ant-table-row-expand-icon {
    &:before {
      display: block !important;
    }
  }
}

.ant-table-filter-column-title {
  padding: 0 !important;
}

.ant-divider.divider-no-mb {
  margin-top: 0;
  margin-bottom: 0;
}

.imagelayer {
  display: none !important;
}

div.ant-result-title {
  color: @white;
}

.ant-upload.ant-upload-drag {
  height: auto !important;
}

.ant-input-number {
  max-width: 100%;
}

.core-result-error {
  color: @black;
  background: @white;

  div.ant-result-title {
    color: @black;
  }

  .bnt-home-block {
    margin-top: 20px;

    .ant-btn-primary {
      background-color: @black;
      color: @turquoise-100;
      font-weight: bold;

      svg {
        color: @green-100;
      }
    }
  }
}

.ant-popover-inner-content {
  min-width: 275px;
}

.svg-rotate-45 {
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(45deg);
}

.ant-layout {
  background: transparent !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

/* ==================================
=            Responsive            =
================================== */

@media screen and (max-width: @breakpoint-md) {
  .footer-block.ant-layout-footer {
    font-size: 10px;

    .footer-link-block {
      text-align: left;

      a {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: @breakpoint-sm) {
  .footer-block.ant-layout-footer {
    height: auto;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .footer-block.ant-layout-footer {
    height: auto;
    text-align: center;

    .footer-link-block {
      text-align: center;
    }

    .version {
      text-align: center;
    }
  }
}

@media screen and (min-width: (@breakpoint-sm + 1)) {
  .visible-md {
    display: none;
  }
}

@media screen and (max-width: @breakpoint-xxs) {
  .footer-block.ant-layout-footer {
    padding: 8px 10px;
  }

  .visible-md {
    display: block;
  }

  .ant-select-tree li {
    white-space: normal !important;
  }

  .ant-select-tree li span.ant-select-tree-switcher {
    width: 5px !important;
    height: 5px !important;
  }

  .ant-select-tree-checkbox {
    top: 5px !important;
    vertical-align: top !important;
  }
}

/* =====  End of Responsive  ====== */
