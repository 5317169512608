@import "~antd/dist/antd.less";
@import "./global.less";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table.data-grid {
  width: 100%;
  margin-bottom: 30px;
}

.data-grid-container table.data-grid {
  .cell {
    height: auto;
    text-align: left;
    padding: 8px;

    &.read-only {
      text-align: left;
    }

    > input {
      width: 100%;
      height: 100%;
      text-align: left;
    }
  }
}
