@import "../../Theme.less";
@import "../../_variables.less";

@panelStyleMargin: 0;
@headerPanelWidth: calc(100% - @panelStyleMargin);
.core-container-panel {
  margin-bottom: @md-spacing;
  border: 1px solid @grey-60;
  background-color: @white;
  box-shadow: 0 2px 30px 2px rgba(0, 0, 0, 0.05);
  padding: @sm-spacing @md-spacing @sm-spacing @md-spacing;

  .header-row {
    width: 100%;
  }

  &:hover {
    background-color: @grey-60;
  }

  &.ant-collapse-item:last-child {
    border-radius: @radius-md;
  }

  &.ant-collapse-item {
    border-radius: @radius-md;
    border-bottom: 1px solid @grey-60;

    .ant-collapse-header[aria-expanded="true"] {
      background-color: @white;
      border-radius: @radius-md;
      padding: 2px 0 4px 0;
      margin-bottom: 5px;

      &.ant-collapse-header-collapsible-only {
        .ant-collapse-header-text {
          cursor: default;
        }
      }

      &:not(.ant-collapse-header-collapsible-only):hover {
        background-color: @grey-60;
      }
    }

    .ant-collapse-header > div {
      display: inline-block;
    }

    .ant-collapse-header > div:last-child {
      width: 100%;
    }
  }

  &:last-child {
    margin: 0;
  }

  .panel-body {
    padding-top: @sm-spacing;
    max-height: 499px;
    overflow-y: auto;

    &.no-scroll {
      max-height: none;
      overflow: hidden;
    }
  }

  .interactions {
    font-size: 12px;
    cursor: pointer;
  }

  .space-interaction {
    height: 32px;
  }

  div.interactions {
    display: block;
    height: 100%;
  }

  .interactions-left {
    display: inline-block;
    margin-right: @sm-spacing;

    .anticon {
      vertical-align: middle;
    }
  }

  .progress-bar {
    height: 6px;
    margin-bottom: @xs-spacing;
  }
}

.image-cover {
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -6px;
}

.core-container.core-container-space-small {
  margin-bottom: @xs-spacing;
}

.core-container.core-container-space-none {
  margin-bottom: 0;
  .core-container-panel-block {
    margin-bottom: 0;
  }
}
.core-container {
  margin-bottom: @md-spacing;
  height: 100%;
  background-color: @white;

  &:last-child {
    margin: 0;
  }

  .ant-collapse-header-text {
    width: 100%;
  }

  .core-container-space-small {
    margin-bottom: @xs-spacing;
    height: 100%;

    .core-container-panel-block {
      height: 100%;
      margin-bottom: @xs-spacing;

      &:last-child {
        margin: 0;
      }
    }
  }

  .core-container-panel-block {
    height: 100%;
    margin-bottom: @md-spacing;
    &:last-child {
      margin: 0;
    }
  }

  .ant-collapse {
    border: none;
    background-color: transparent;
    height: 100%;

    .core-container-panel {
      height: 100%;

      &.ant-collapse-item-active {
        background-color: @white;
      }

      &.ant-collapse-item > .ant-collapse-header {
        .collapse-header-block {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          font-style: normal;
          color: @grey-140;

          &.ant-row {
            cursor: default;
          }

          h2.ant-typography {
            margin-bottom: 0;
          }
        }

        .header-expand-icon {
          font-size: 16px;
          color: @grey-140;
          padding: @xs-spacing 0 @xs-spacing 0;
          margin: 0 18px 0 6px;
        }
      }

      &.ant-collapse-item-disabled {
        background-color: @grey-20;
        opacity: 0.5;

        .ant-collapse-header {
          cursor: not-allowed;
          .collapse-header-block {
            h2.ant-typography {
              color: @grey-100;
            }
          }

          .header-expand-icon {
            color: @grey-100;
          }
        }
      }

      .ant-progress-text {
        display: none;
      }

      div.ant-progress-outer {
        padding-right: 0;
      }

      .ant-progress-inner {
        margin-top: -18px;
      }

      .ant-collapse-content {
        margin-top: @xxs-spacing;
        border-radius: 0 0 @radius-md @radius-md;
        border-top: 1px solid @grey-80;
      }

      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }
    }

    .panel-style {
      padding: @md-spacing;
      background-color: @white;

      &.ant-collapse-item {
        // background: 0 !important;
        border-color: @grey-60;

        > &:last-child {
          border-radius: 0 0 @radius-md @radius-md;
          box-shadow: none;
        }

        > .ant-collapse-header {
          border-radius: 0 !important;
          padding-bottom: 0 !important;
        }

        > .ant-collapse-content {
          border-top: 0 !important;

          &:before {
            content: "";
            display: block;
            width: @headerPanelWidth;
            height: 1px;
            background: @grey-80;
            margin: 0 auto 0;
          }

          > .ant-collapse-content-box {
            padding: 0;

            .panel-body {
              padding: 0;
            }
          }
        }
      }

      &.round-corner-panel {
        &.ant-collapse-item {
          &:last-child {
            border-radius: @radius-md;
          }

          .ant-collapse-header {
            border-radius: @radius-md !important;
          }
        }
      }

      &:not(.round-corner-panel) {
        &.ant-collapse-item {
          &:last-child {
            border-radius: 0 0 @radius-md @radius-md;
          }
        }
      }
    }
  }

  .ant-collapse-borderless {
    .ant-collapse-item {
      border: none;
    }
  }

  .ant-collapse-ghost {
    .ant-collapse-item {
      box-shadow: none;
      padding: 0;
    }
  }

  .panel-dropdown-popup {
    z-index: 99;
  }

  .panel-dropdown-popup .ant-dropdown-menu-title-content > div {
    width: 100%;
  }
}
