@import "../../Theme";
@import "../../_variables";

.date-to-on-right {
  div:nth-child(3) {
    &.ant-picker-input {
      > input {
        text-align: right;
      }
    }
  }

  .ant-picker-suffix {
    margin-left: 12px;
  }
}

.extended-date-btn {
  .ant-radio-button-wrapper {
    color: @grey-120;
    font-weight: 700;
    border-color: @grey-120;

    &:first-child {
      border-radius: 20px 0 0 20px;
    }

    &:last-child {
      border-radius: 0 20px 20px 0;
    }

    &:not(:first-child):before {
      background: @grey-120;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: @green-120;
    border-color: @green-120;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background: @green-100;
  }
}

.tooltip-btn {
  display: inline-block;
  margin-left: 10px;

  &.ant-btn {
    border-radius: 50%;
    border-color: @grey-100;
  }
}
