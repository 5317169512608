@import "../../Theme";
@import "../../_variables";

.core-btn {
  font-family: "Inter", serif;
  position: relative;
  display: inline-flex;
  height: 32px;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5;
  color: @grey-140;
  white-space: nowrap;
  text-align: center;
  border: 1px solid @grey-100;
  border-radius: 20px;
  background: @white;
  padding: 5px 12px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;

  &.border-radius-sm {
    border-radius: 4px;
  }

  &.block {
    width: 100%;
    display: block;
    box-sizing: border-box;
  }

  &.loading {
    opacity: 0.6;
    cursor: default;
  }

  &.icon-only {
    padding-left: 6px;
    padding-right: 6px;
  }

  &.danger {
    color: @indigo-60;
    border-color: @indigo-60;

    &.loading {
      color: @grey-140;
      border-color: @grey-80;
    }
  }

  &:not([disabled]):not(.loading) {
    &:hover,
    &:focus {
      color: @green-140;
      border-color: @green-140;
    }

    &:active {
      color: @green-160;
      border-color: @green-160;
    }

    &.danger {
      &:hover,
      &:focus {
        color: @indigo-100;
        border-color: @indigo-100;
      }

      &:active {
        color: @indigo-140;
        border-color: @indigo-140;
      }
    }
  }

  &[disabled] {
    color: @grey-100 !important;
    border-color: @grey-100 !important;
    background: @grey-40 !important;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.md {
    svg {
      font-size: 16px;
    }

    span {
      margin-left: 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.lg {
    height: 38px;
    font-size: 16px;
    padding: 7px 16px;

    svg {
      font-size: 20px;
    }

    &.icon-only {
      padding: 4px 6px 5px 6px;
    }

    > span {
      margin-left: 6px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.sm {
    height: 26px;
    font-size: 13px;
    padding: 2px 8px;

    svg {
      font-size: 12px;
    }

    &.icon-only {
      padding-left: 5px;
      padding-right: 5px;
    }

    > span {
      margin-left: 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.primary {
    color: @white;
    background: @green-100;
    border-color: @green-100;

    &.danger {
      background: @indigo-60;
      border-color: @indigo-60;

      &.loading {
        color: @white;
        border-color: @indigo-80;
      }
    }

    &:not([disabled]):not(.loading) {
      &:hover,
      &:focus {
        color: @white;
        background: @green-140;
      }

      &:active {
        color: @white;
        background: @green-160;
        border-color: @green-160;
      }

      &.danger {
        &:hover,
        &:focus {
          color: @white;
          border-color: @indigo-100;
          background: @indigo-100;
        }

        &:active {
          color: @white;
          border-color: @indigo-140;
          background: @indigo-140;
        }
      }
    }
  }

  &.text {
    background: 0;
    border-color: transparent;

    &.danger {
      color: @grey-140;
      border-color: transparent;

      &.loading {
        background: rgba(214, 216, 235, 0.1);
      }

      &[disabled] {
        background: @white !important;
        border-color: @grey-100 !important;
      }
    }

    &.loading {
      background: @grey-20;
    }

    &:not([disabled]):not(.loading) {
      &:hover,
      &:focus {
        color: @grey-140;
        background: @grey-40;
        border-color: transparent;
      }

      &:active {
        color: @grey-140;
        background: @grey-60;
        border-color: transparent;
      }

      &.danger {
        &:hover,
        &:focus {
          color: @indigo-100;
          border-color: transparent;
          background: rgba(214, 216, 235, 0.2);
        }

        &:active {
          color: @indigo-140;
          border-color: transparent;
          background: rgba(214, 216, 235, 0.5);
        }
      }
    }

    &[disabled] {
      border-color: transparent !important;
    }
  }

  &.link {
    color: @green-100;
    background: 0;
    border-color: transparent;
    padding: 0;

    &.danger {
      color: @indigo-60;
      border-color: transparent;

      &.loading {
        background: transparent;
      }

      &[disabled] {
        background: transparent !important;
        border-color: transparent !important;
      }
    }

    &.loading {
      color: @grey-140;
      background: transparent;
    }

    &:not([disabled]):not(.loading) {
      &:hover,
      &:focus {
        color: @green-120;
        background: transparent;
        border-color: transparent;
      }

      &:active {
        color: @green-160;
        background: transparent;
        border-color: transparent;
      }

      &.danger {
        &:hover,
        &:focus {
          color: @indigo-100;
          border-color: transparent;
          background: transparent;
        }

        &:active {
          color: @indigo-140;
          border-color: transparent;
          background: transparent;
        }
      }
    }

    &[disabled] {
      border-color: transparent !important;
      background: transparent !important;
    }
  }

  &.action {
    color: @grey-120;
    background: 0;
    border: none;
    padding: 0;

    &.danger {
      &.loading {
        background: transparent;
      }

      &[disabled] {
        background: transparent !important;
      }
    }

    &.loading {
      color: @grey-140;
      background: transparent;
    }

    &:not([disabled]):not(.loading) {
      &:hover,
      &:focus {
        color: @green-120;
        background: transparent;
      }

      &:active {
        color: @green-160;
        background: transparent;
      }

      &.danger {
        &:hover,
        &:focus {
          color: @red-100;
          background: transparent;
        }

        &:active {
          color: @red-140;
          background: transparent;
        }
      }
    }

    &[disabled] {
      background: transparent !important;
    }
  }

  > span {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
}
