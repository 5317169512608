@import "../../_variables.less";
@import "../../Theme.less";

.core-top-header {
  .user-panel-section {
    @media screen and (max-width: @breakpoint-sm) {
      position: relative;
      top: 0px;
      z-index: 150;
    }
  }

  .top-header-row {
    background: @indigo-140;

    &.dev-env {
      background: @turquoise-140;
    }
  }

  .top-header-progress-bar {
    position: relative;

    .top-header-logo-border {
      width: 264px;
      background: @gradient;
      height: 4px;
      position: absolute;

      @media screen and (max-width: @breakpoint-sm) {
        width: 55px;
        background: @gradientMobile;
      }

      &.dev-env {
        background: @gradientDev;

        @media screen and (max-width: @breakpoint-sm) {
          background: @gradientMobileDev;
        }
      }
    }

    .top-header-progress-bar-block {
      line-height: 0px;
      display: flex;
      flex: 1 1 auto;
      position: absolute;
      width: 100%;
    }
  }

  .top-header-logo {
    height: 60px;
    width: 100px;
    margin-right: 15px;

    .logo-image {
      display: inline-flex;
      align-self: center;
      padding-top: 12px;
      padding-left: 15px;
    }
  }

  .top-header-logo-block {
    @media screen and (max-width: @breakpoint-sm) {
      display: none;
    }
  }

  .breadcrumb-top-header-block {
    padding-left: 16px;

    @media screen and (max-width: @breakpoint-sm) {
      display: none;
    }
  }

  .user-panel-top-header-block {
    &:hover {
      background-color: @indigo-100;
      border-radius: 20px 0 0 20px;
    }

    @media screen and (max-width: @breakpoint-sm) {
      position: absolute;
      right: 0;
    }
  }

  .mobile-hamburger-icon-block {
    display: none;
    width: 55px;
    height: 60px;
    font-size: 1.5em;
    color: @white;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    z-index: 150;
    transition: left 0.5s;

    @media screen and (max-width: @breakpoint-sm) {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: @green-80;

        svg {
          fill: @white;
        }
      }
    }
  }

  .header-mobile-size-block {
    position: fixed;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    height: 60px;
    min-height: 0;
    border-right: 0;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: @breakpoint-sm) {
      display: flex;
    }
  }

  .public-login-block {
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;

    .login-icon {
      margin-right: 10px;
    }
  }
}
