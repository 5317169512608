@import "../../../_variables";
@import "../../../Theme";

@mobile_header_height: 60px;

.dashboard-layout-block {
  .mobile-menu-active {
    @media screen and (max-width: @breakpoint-sm) {
      .mobile-hamburger-icon {
        position: fixed;
        left: @mobile_sidebar_width;
        z-index: 100;
      }

      .dashboard-sidebar {
        z-index: 100;
      }
    }
  }
  .main-content-block {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 161.5px);
  }

  .dashboard-sidebar {
    min-height: 100vh;
  }
}

.top-header-sticky {
  position: fixed;
  top: 0;
  z-index: 2001;
  padding: 0px;
  line-height: inherit;
  width: 100%;
}

.content-layout {
  padding-top: 64px;
}

.main-layout-outer-block {
  flex: auto;
  background: @grey-10;

  @media screen and (min-width: 1921px) {
    position: relative;
  }
}

.main-layout-content-block {
  margin: 0 auto;
}

.main-layout-content-blur {
  filter: blur(20px);
}
