@import "../../Theme";
@import "../../_variables";

.core-select {
  .set-width();
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: pointer;
  }

  text-align: initial;

  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  .ant-select-selector {
    align-items: center;
  }

  .ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 26px;
  }

  .ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
  }

  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 26px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 38px;
  }

  .ant-select-allow-clear {
    .ant-select-clear {
      .anticon-close-circle svg {
        fill: @grey-140;
      }
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: @grey-40;
    border: 1px solid @grey-100;
    opacity: 0.5;
  }

  .ant-select-disabled {
    .ant-select-arrow {
      .anticon-caret-down svg {
        fill: @grey-120;
        opacity: 0.5;
      }
    }
  }

  .ant-select-selection-placeholder {
    color: @grey-100;
  }

  .anticon-caret-down:not(:hover) svg {
    fill: @grey-100;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-arrow svg {
      fill: @green-100;
    }
  }

  .ant-select:not(.ant-select-customize-input) > .ant-select-selector {
    border: 1px solid @grey-100;
    border-radius: 20px;
    box-sizing: border-box;

    &:hover {
      border: 1px solid @green-100;
    }
  }

  .ant-select {
    font-family: @body-typeface;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: @grey-140;
    width: 100%;
  }

  .ant-select:hover:not(.ant-select-disabled) {
    font-family: @body-typeface;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: @grey-140;

    .ant-select-arrow svg {
      fill: @green-100;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  .ant-select:not(.ant-select-customize-input).ant-select-disabled
    > .ant-select-selector {
    border: 1px solid @grey-100;
    cursor: not-allowed;
  }

  .ant-select-multiple .ant-select-selection-item {
    border: 1px solid @grey-100;
    box-sizing: border-box;
    border-radius: 40px;
    height: 22px;
    line-height: 20px;
  }

  .ant-select-multiple {
    .ant-select-selection-overflow {
      .ant-tag {
        margin-right: 2px;
      }
    }

    &.ant-select-lg {
      .ant-select-selection-item {
        height: 22px;
        line-height: 20px;
      }
    }

    &.ant-select-sm {
      .ant-select-selection-item {
        height: 22px;
        line-height: 19.4px;
        margin-top: 0;
      }

      &.ant-select-multiple .ant-select-selector {
        padding: 1px 26px 1px 1px;
      }

      .ant-select-selection-overflow-item {
        text-align: center;
      }
    }
  }
}

.core-select-dropdown {
  &.ant-select-dropdown {
    box-sizing: border-box;
    border: 1px solid @green-100;
    border-radius: 14px;
    font-family: @body-typeface;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: @grey-140;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    .ant-select-item-option-state {
    color: @lime-100;
  }

  .ant-select-multiple .ant-select-selector {
    padding: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @green-10;
    border-radius: 4px;
    color: @grey-140;
    font-weight: 700;

    &:first-child {
      background-color: @green-10;
      border-radius: 12px 12px 4px 4px;
    }

    &:last-child {
      background-color: @green-10;
      border-radius: 4px 4px 12px 12px;
    }

    &:hover {
      color: @white;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
    .anticon-check-circle svg {
      fill: @white;
    }
  }

  .ant-select-item {
    font-family: @body-typeface;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: @grey-140;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .ant-select-item-option-disabled {
    color: @grey-100;
    background-color: @grey-40;
    border-radius: 4px;
    opacity: 0.5;

    &:first-child {
      border-radius: 12px 12px 4px 4px;
    }

    &:last-child {
      &:nth-child(1) {
        border-radius: 12px 12px 12px 12px;
      }

      border-radius: 4px 4px 12px 12px;
    }
  }

  .ant-select-item-option:not(.ant-select-item-option-disabled, .ant-select-item-option-selected) {
    background-color: @white;
    border-radius: 4px;

    &:first-child {
      background-color: @white;
      border-radius: 12px 12px 4px 4px;
    }

    &:last-child {
      background-color: @white;
      border-radius: 4px 4px 12px 12px;
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: @green-100;
    border-radius: 4px;
    color: @white;

    &:first-child {
      background-color: @green-100;
      border-radius: 12px 12px 4px 4px;
    }

    &:last-child {
      &:nth-child(1) {
        background-color: @green-100;
        border-radius: 12px 12px 12px 12px;
      }

      background-color: @green-100;
      border-radius: 4px 4px 12px 12px;
    }
  }

  .ant-select-item-group {
    color: @grey-120;
    font-weight: 700;
    border-radius: 0;
    border-bottom: 1px @grey-80 solid;
    margin-bottom: 2px;
  }
  .ant-select-item-option-grouped {
    padding-left: 16px;
  }
}
