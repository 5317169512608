@import "../../Theme";
@import "../../_variables";

.core-form {
  display: grid;
  grid-gap: 8px;

  &:not(.vertical) {
    &.sm {
      .label-block div {
        height: 24px;
      }

      .form-item-block {
        min-height: 24px;
      }
    }

    &.md {
      .label-block div {
        height: 32px;
      }

      .form-item-block {
        min-height: 32px;
      }
    }

    &.lg {
      .label-block div {
        height: 38px;
      }

      .form-item-block {
        min-height: 38px;
      }
    }
  }

  &.vertical {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .label-block {
      &.no-label {
        display: none;
      }
      margin-left: 4px;
    }

    .indent {
      margin-left: 4px;
    }
  }

  > * {
    grid-column: ~"1 / 3";
  }

  .label-block {
    grid-column: unset;
    div {
      display: flex;
      align-items: center;
      height: 100%;
    }

    div.sm {
      height: 24px;
    }

    div.md {
      height: 32px;
    }

    div.lg {
      height: 38px;
    }
  }

  .form-item-block {
    display: flex;
    align-items: center;
  }

  .required-mark {
    color: @red-100;
    margin-left: 4px;
  }

  .form-item-info-icon {
    margin-left: 4px;
  }
}

.core-form-item {
  .form-item-width-full {
    width: 100%;
  }

  .set-width();

  .ant-form-item-has-feedback .ant-select .ant-select-arrow {
    right: 10px;
  }

  .ant-form-item-label > label {
    margin-right: 8px;

    &:after {
      display: none;
    }
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    content: "*";
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: @x-tight;
    color: @red-100;
  }

  .ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-explain {
      font-family: "Inter", sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: @regular;
      font-style: italic;
      color: @grey-140;
      margin-left: 4px;
      min-height: unset;
      margin-top: 2px;
    }
  }

  .ant-form-item-control-input {
    min-height: unset;
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }

  .ant-form-item-has-error {
    &:not(.ant-input-disabled) {
      &:not(.ant-input-borderless) {
        .character-limit {
          .ant-typography {
            color: @red-80;
          }
        }

        .ant-input {
          border-color: @red-80;
          color: @grey-140;

          &:not([disabled]) {
            &:hover,
            &:focus {
              border-color: @red-80;
            }
          }
        }

        .ant-input-number {
          border-color: @red-80;
          color: @grey-140;
        }

        .ant-form-item-children-icon {
          color: @red-80;
          font-size: 15px;
        }

        .ant-picker {
          border-color: @red-80;
          color: @grey-140;

          &:not([disabled]) {
            &:hover,
            &:focus {
              border-color: @red-80;
            }
          }

          &:not(.ant-picker-disabled).ant-picker-focused {
            border-color: @red-80;
          }
        }

        .ant-input-group-wrapper {
          .ant-input-group-addon {
            border-color: @red-80;
          }
        }

        .ant-form-item-explain-error {
          color: @red-80;
        }

        .ant-input-affix-wrapper {
          &:not(.core-input-success)&:not(.core-input-warning)
            &:not(.ant-input-affix-wrapper-disabled) {
            border-color: @red-80;

            &:hover,
            &:focus {
              border-color: @red-80;
            }
          }

          .ant-input-prefix {
            [role="img"] {
              color: @red-80;
            }
          }

          .ant-input-suffix {
            [role="img"] {
              &:not(:first-child) {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .ant-form-item-has-warning {
    &:not(.ant-input-disabled) {
      &:not(.ant-input-borderless) {
        .ant-input {
          border-radius: 20px;
          border-color: @orange-80;
          color: @grey-140;

          &:not([disabled]) {
            &:hover,
            &:focus {
              border-color: @orange-80;
            }
          }
        }

        .ant-form-item-children-icon {
          color: @orange-80;
          font-size: 15px;
        }

        .ant-form-item-explain-warning {
          color: @orange-80;
        }

        .ant-input-affix-wrapper {
          &:not(.core-input-success)&:not(.core-input-error)
            &:not(.ant-input-affix-wrapper-disabled) {
            border-color: @orange-80;

            &:hover,
            &:focus {
              border-color: @orange-80;
            }
          }

          .ant-input-prefix {
            [role="img"] {
              color: @orange-80;
            }
          }

          .ant-input-suffix {
            [role="img"] {
              &:not(:first-child) {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .ant-form-item-has-success {
    &:not(.ant-input-disabled) {
      &:not(.ant-input-borderless) {
        .ant-input {
          border-color: @lime-100;
          color: @grey-140;

          &:not([disabled]) {
            &:hover,
            &:focus {
              border-color: @lime-100;
            }
          }
        }

        .ant-form-item-children-icon {
          color: @lime-100;
          font-size: 15px;
        }

        .ant-form-item-explain-success {
          color: @lime-100;
        }

        .ant-input-group-wrapper {
          .ant-input-group-addon {
            border-color: @lime-100;
          }
        }

        .ant-input-affix-wrapper {
          &:not(.core-input-warning)&:not(.core-input-error)
            &:not(.ant-input-affix-wrapper-disabled) {
            border-color: @lime-100;

            &:hover,
            &:focus {
              border-color: @lime-100;
            }
          }

          .ant-input-prefix {
            [role="img"] {
              color: @lime-100;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0;
    line-height: inherit;
    white-space: initial;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .core-form {
    &:not(.horizontal) {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &.sm,
      &.md,
      &.lg {
        .label-block div {
          height: unset;
        }

        .form-item-block {
          min-height: unset;
        }
      }

      .label-block {
        margin-left: 4px;
        &.no-label {
          display: none;
        }
      }

      .indent {
        margin-left: 4px;
      }
    }
  }
}
