@import "../../Theme.less";
@weight-tooltip: 230px;

.core-tooltip {
  z-index: 2001;
}

.title-message {
  color: @grey-180 !important;
  margin-bottom: 8px !important;
}

.body-message {
  color: @grey-160;
  font-size: 14px;
  line-height: 20px;
}

.core-tooltip-container {
  width: fit-content;
  block-size: fit-content;
}
