@import "../../Theme.less";
@import "../../_variables.less";

@grey20: @grey-20;

// grey40
@grey40: @grey-40;

// grey80
@grey80: @grey-80;

// grey100
@grey100: @grey-100;

// grey120
@grey120: @grey-120;

// grey14
@grey140: @grey-140;

// grey180
@grey180: @grey-180;

// indigo10
@indigo10: @indigo-10;

// indigo80
@indigo80: @indigo-80;

// green100
@green100: @green-100;

// turquoise10
@turquoise10: @turquoise-10;

// turquoise100
@turquoise100: @turquoise-100;

// purple10
@purple10: @purple-100;

// purple100
@purple100: @purple-100;

// blue10
@blue10: @blue-10;

// blue100
@blue100: @blue-100;

// lime10
@lime10: @lime-10;

// lime100
@lime100: @lime-100;

// yellow10
@yellow10: @yellow-10;

// yellow100
@yellow100: @yellow-100;

// orange10
@orange10: @orange-10;

// orange100
@orange100: @orange-100;

// red10
@red10: @red-10;

// red100
@red100: @red-100;

// pink10
@pink10: @pink-10;

// pink100
@pink100: @pink-100;

.core-divider {
  @colors: indigo10, indigo80, grey20, grey40, grey80, grey100, grey120, grey140,
    turquoise10, turquoise100, blue100, blue10, lime10, lime100, yellow10,
    yellow100, purple10, purple100, orange10, orange100, red10, red100, pink10,
    pink100, black, white;

  @font-weights: lighter, normal, bold;

  .for-each-colors-loop(@index) when (@index > 0) {
    @color: extract(@colors, @index);
    &.label-bg-@{color} {
      .ant-divider-inner-text {
        background-color: ~"@{@{color}}";
      }
    }
    &.label-color-@{color} {
      .ant-divider-inner-text {
        .ant-typography {
          color: ~"@{@{color}}";
        }
      }
    }
    .for-each-colors-loop(@index - 1);
  }
  .for-each-colors-loop(length(@colors));

  .for-each-font-weight-loops(@index) when (@index > 0) {
    @font-weight: extract(@font-weights, @index);
    &.label-weight-@{font-weight} {
      .ant-divider-inner-text {
        .ant-typography {
          font-weight: ~"@{font-weight}";
        }
      }
    }
    .for-each-font-weight-loops(@index - 1);
  }
  .for-each-font-weight-loops(length(@font-weights));

  .ant-divider-inner-text {
    padding: 2px 0 4px 0;
    border-radius: 4px;
  }

  &.ant-divider-horizontal {
    &.ant-divider-with-text {
      &:after,
      &:before {
        border-top-color: @grey-80;
        border-top-width: 1.5px;
      }
    }
    &.xl {
      margin: @xl-spacing 0;
    }

    &.lg {
      margin: @lg-spacing 0;
    }

    &.md {
      margin: @md-spacing 0;
    }

    &.sm {
      margin: @sm-spacing 0;
    }

    &.xs {
      margin: @xs-spacing 0;
    }
  }

  &.ant-divider-vertical {
    &.xl {
      margin: 0 @xl-spacing;
    }

    &.lg {
      margin: 0 @lg-spacing;
    }

    &.md {
      margin: 0 @md-spacing;
    }

    &.sm {
      margin: 0 @sm-spacing;
    }

    &.xs {
      margin: 0 @xs-spacing;
    }
  }

  &.none {
    margin: 0;
  }

  &.ant-divider {
    border-top-color: @grey-80;
    border-top-width: 1.5px;
  }
  .body-padding {
    padding: 0 8px;
  }
}
