@import "../../Theme.less";
@import "../../_variables";

.back-to-top {
  .ant-back-top {
    right: 40px;

    @media (min-width: @breakpoint-sm) {
      right: 48px;
    }
    @media (min-width: @breakpoint-md) {
      right: 56px;
    }
    bottom: 92px;
    @media (min-width: @breakpoint-xxs) {
      bottom: 62px;
    }
    @media (min-width: @breakpoint-sm) {
      bottom: 66px;
    }
    @media (min-width: @breakpoint-md) {
      bottom: 70px;
    }
  }
}
