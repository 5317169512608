@import "../../Theme.less";
@import "../../_variables.less";

.mr-10 {
  margin-right: 10px;
}

.core-breadcrumb {
  .display-inline {
    display: inline-block;
  }

  .overflow-breadcrumb {
    overflow-x: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  .hover-underline {
    &:hover {
      text-decoration: underline;
    }
  }

  .ant-breadcrumb {
    font-size: 14px;
    font-family: @body-typeface;

    .ant-breadcrumb-separator {
      color: @green-120;
    }

    span {
      :not(a) &.ant-breadcrumb-link {
        color: @green-120;
      }
    }

    span a {
      color: @green-120;
      font-style: normal;
      font-size: 14px;
    }
  }

  &:not(.breadcrumb-for-small-screen) {
    .ant-breadcrumb {
      span:last-child a {
        color: @turquoise-80;
      }

      span:last-child .ant-breadcrumb-link {
        color: @turquoise-80;
      }
    }
  }
}
