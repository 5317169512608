@import "../../_variables";
@import "../../Theme";
svg {
  .prefix__cls-3 {
    fill: @green-100;
  }

  &:hover {
    path.white-text {
      fill: @green-100;
    }
  }
}
