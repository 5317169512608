@import "../../Theme.less";

@menu-width: 264px;
@mobile-menu-width: calc(@menu-width + 60px);
@bar-width: 52px;
@transition-animation: width 0.6s ease;

.core-main-menu-block-clickable-item {
  cursor: pointer;
}

.main-menu-container {
  z-index: 1000;
  &.mobile {
    z-index: 2001;
  }
}

.side-nav-block {
  width: 264px;
  transition: @transition-animation;

  &.bar {
    width: 52px;
  }

  &.mobile {
    width: 0;
  }
}

.mobile-overlay {
  background-color: @black;
  opacity: 0.8;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  top: 0;
}

.core-main-menu {
  width: @menu-width;
  height: 100%;
  transition: @transition-animation;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 2000;

  ul {
    padding-inline-start: 4px;
  }

  .menu-content {
    transition: @transition-animation;
    background-color: @main-menu-background;
    flex-grow: 1;
    border-bottom-right-radius: 24px;
    width: 264px;
    overflow: hidden;
    top: 64px;
    position: fixed;
    scrollbar-width: none;
    padding-bottom: 0;
    height: calc(100vh - 64px);

    .hidden-scrollable {
      transition: @transition-animation;
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      right: -11px;
      top: 0;
      bottom: 48px;
      left: 0;
    }

    .toggle-button-row {
      transition: @transition-animation;
      position: fixed;
      bottom: 0;
    }
  }

  &.mobile {
    width: @mobile-menu-width;
    top: 0;

    .toggle-button {
      display: none;
    }

    .mobile-header {
      display: block;
      position: fixed;
      z-index: 2000;
      width: @mobile-menu-width;
      transition: @transition-animation;

      .menu-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 60px;
        background-color: @main-menu-background;
        border-radius: 0 30px 30px 0;

        .logo {
          padding-left: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .close-button {
          color: @main-menu-active;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            border-radius: 30px;
            background-color: @main-menu-active;
            color: @main-menu-default-text;
            cursor: pointer;
          }
        }
      }
      .progress-gradient {
        width: @menu-width;
        transition: @transition-animation;
        height: 4px;
        background: linear-gradient(to right, #499ce9, #00e9ac);
      }
    }

    .menu-content {
      top: 64px;
      position: fixed;
      overflow: hidden;
      scrollbar-width: none;
      padding-bottom: 0;
      height: calc(100vh - 64px);

      .hidden-scrollable {
        top: 0;
      }
    }

    .toggle-button-row {
      transition: @transition-animation;
      position: fixed;
      bottom: 0;
    }
  }

  &.bar {
    width: @bar-width;

    .menu-content {
      width: @bar-width;

      .hidden-scrollable {
        width: @bar-width;
        overflow-y: hidden;
      }
    }

    .toggle-button-row {
      width: @bar-width;
    }

    .rc-menu {
      width: @bar-width;
    }

    .ant-typography.menu {
      display: none;
    }
  }

  .toggle-button-row {
    width: @menu-width;
    transition: @transition-animation;
    height: 48px;
    border-bottom-right-radius: 24px;
    border-top: 1px solid @indigo-120;
    background-color: @main-menu-background;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;

    .toggle-button {
      border-radius: 24px;
      color: @main-menu-default-text;
      opacity: 0.6;
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: @white;
        opacity: 1;
        background-color: @main-menu-active;
        cursor: pointer;
      }
    }
  }

  .mobile-header {
    display: none;
  }

  .rc-menu-hidden {
    display: none;
  }

  .rc-menu {
    transition: @transition-animation;

    .rc-menu-sub {
      .core-main-menu-divider-no-children {
        display: none;
      }
    }

    &.rc-menu-vertical {
      .expand-icon {
        display: none;
      }
    }

    &.rc-menu-root {
      background-color: @main-menu-background;
      margin-bottom: 0;
      margin-top: 4px;
      padding: 0 6px 0 4px;

      .rc-menu-item {
        background-color: @main-menu-background;
        height: 48px;
        width: 100%;
        padding: 11px 14px 11px 14px !important;
        list-style: none;

        .core-typography-input {
          .ant-typography {
            color: @main-menu-default-text;
            opacity: 0.6;
          }
        }

        .core-main-menu-icon {
          color: @main-menu-default-text;
          opacity: 0.6;

          .regular-icon {
            display: block;
          }
          .solid-icon {
            display: none;
          }
        }

        &:hover {
          .ant-typography.menu,
          .ant-typography.default {
            border-bottom: 1px @main-menu-active solid;
            color: @main-menu-active;
            white-space: nowrap;
            opacity: 1;
            font-weight: 600;
          }

          .core-main-menu-icon {
            color: @main-menu-active;
            opacity: 1;

            .regular-icon {
              display: none;
            }

            .solid-icon {
              display: block;
            }
          }
        }

        &.rc-menu-item-selected {
          .ant-typography.menu,
          .ant-typography.default {
            color: @main-menu-active;
            opacity: 1;
            white-space: nowrap;
            font-weight: 600;
          }

          .core-main-menu-icon {
            color: @main-menu-active;
            opacity: 1;

            .regular-icon {
              display: none;
            }

            .solid-icon {
              display: block;
            }
          }
        }

        &.rc-menu-item-disabled {
          background-color: rgba(255, 255, 255, 0.25);
          opacity: 0.5;

          .core-main-menu-icon {
            color: @main-menu-default-text;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &.hidden {
    &.mobile {
      width: 0;
      overflow: hidden;

      .mobile-header {
        width: 0;

        .logo {
          width: 0;
        }

        .close-button {
          display: none;
        }

        .progress-gradient {
          width: 0;
        }
      }

      .menu-content {
        width: 0;

        .toggle-button-row {
          width: 0;
        }
      }
    }
  }
}

.core-main-menu :not(.rc-menu-submenu) .rc-menu-item {
  border-radius: 8px;
}

.core-main-menu .rc-menu-submenu,
.core-main-menu-popup.rc-menu-submenu {
  list-style: none;
  padding-bottom: 0;

  .core-main-menu-divider {
    padding: 0 12px 0 12px;

    .ant-divider {
      border-top-color: @main-menu-divider;
    }
  }

  .rc-menu-sub {
    .rc-menu-submenu::marker {
      content: "";
    }
  }

  .rc-menu-submenu-title {
    cursor: pointer;
    background-color: @main-menu-background;
    height: 48px;
    width: 100%;
    padding: 11px 12px 11px 12px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .expand-icon {
      color: @main-menu-default-text;
      opacity: 0.6;
    }

    .core-main-menu-icon {
      color: @main-menu-default-text;
      opacity: 0.6;

      .regular-icon {
        display: block;
      }

      .solid-icon {
        display: none;
      }
    }

    .ant-typography.menu,
    .ant-typography.default {
      color: @main-menu-default-text;
      opacity: 0.6;
      white-space: nowrap;
    }
  }

  &.rc-menu-submenu-hidden {
    display: none;
    border-radius: 8px;
  }

  &.rc-menu-submenu-selected,
  &.rc-menu-submenu-open,
  .rc-menu-submenu-open {
    background-color: @main-menu-active-background;
    border-radius: 8px;

    ul {
      padding-inline-start: 16px;
    }

    > .rc-menu-submenu-title {
      .ant-typography.menu,
      .ant-typography.default {
        color: @main-menu-active;
        opacity: 1;
        white-space: nowrap;
        font-weight: 600;
      }

      .core-main-menu-icon {
        color: @main-menu-active;
        opacity: 1;

        .regular-icon {
          display: none;
        }

        .solid-icon {
          display: block;
        }
      }

      .expand-icon {
        color: @main-menu-active;
        opacity: 1;
      }

      .ant-typography.menu {
        opacity: 1;
        white-space: nowrap;
        color: @main-menu-active;
        font-weight: 600;
      }
    }

    .rc-menu-submenu-title {
      background-color: @main-menu-active-background;
      border-radius: 8px;
    }

    .rc-menu-item {
      background-color: @main-menu-active-background;
      border-radius: 8px;
    }

    .rc-menu-sub {
      .rc-menu-item:hover {
        cursor: pointer;

        .ant-typography.default {
          border-bottom: 1px @main-menu-active solid;
          color: @main-menu-active;
          opacity: 1;
          white-space: nowrap;
          font-weight: 600;
        }

        .core-main-menu-icon {
          color: @main-menu-active;
          opacity: 1;
        }
      }

      .rc-menu-item-selected {
        background-color: @main-menu-active-background;

        .core-main-menu-icon {
          color: @main-menu-default-text;

          .regular-icon {
            display: block;
          }

          .solid-icon {
            display: none;
          }
        }

        .ant-typography.default {
          color: @main-menu-active;
          font-weight: 600;
          opacity: 1;
          white-space: nowrap;
        }
      }
    }
  }

  &.rc-menu-submenu-selected:not(.rc-menu-submenu-open) {
    .rc-menu-submenu-title {
      background-color: @main-menu-background;
    }
  }

  &.rc-menu-submenu {
    > .rc-menu-submenu-title:hover {
      border-radius: 8px;

      .core-main-menu-icon {
        color: @main-menu-active;
        opacity: 1;

        .regular-icon {
          display: none;
        }

        .solid-icon {
          display: block;
        }
      }

      .expand-icon {
        color: @main-menu-active;
        opacity: 1;
      }

      .ant-typography.menu,
      .ant-typography.default {
        opacity: 1;
        white-space: nowrap;
        color: @main-menu-active;
        border-bottom: 1px @main-menu-active solid;
        font-weight: 600;
      }
    }
  }

  &.rc-menu-submenu-disabled {
    .rc-menu-submenu-title {
      background-color: rgba(255, 255, 255, 0.25);
      border-radius: 8px;
      opacity: 0.5;

      .core-main-menu-icon {
        color: @main-menu-default-text;
        opacity: 0.5;
      }

      .expand-icon {
        color: @main-menu-default-text;
        opacity: 0.5;
      }
    }
  }
}

.core-main-menu-popup.rc-menu-submenu {
  position: fixed;
  background-color: @main-menu-background;
  border-radius: 0 12px 12px 0;
  width: @menu-width;
  z-index: 999;
  padding: 4px;

  .rc-menu.rc-menu-sub {
    margin: 0;
    border-radius: 0 12px 12px 0;
    padding: 0 12px 0 24px;
    background-color: @main-menu-active-background;

    .core-main-menu-divider-no-children {
      padding: 0 12px 0 12px;

      .ant-divider {
        border-top-color: @main-menu-divider;
      }
    }

    .rc-menu-submenu-title {
      background-color: @main-menu-active-background;

      &:hover {
        border-radius: 8px;

        .core-main-menu-icon {
          color: @main-menu-active;
          opacity: 1;

          .regular-icon {
            display: none;
          }

          .solid-icon {
            display: block;
          }
        }

        .expand-icon {
          color: @main-menu-active;
          opacity: 1;
        }

        .ant-typography.menu,
        .ant-typography.default {
          opacity: 1;
          white-space: nowrap;
          color: @main-menu-active;
          border-bottom: 1px @main-menu-active solid;
          font-weight: 600;
        }
      }
    }

    .rc-menu-item {
      padding: 11px 14px 11px 14px;
      list-style: none;

      .core-typography-input {
        .ant-typography.default {
          color: @main-menu-default-text;
          opacity: 0.7;
        }
      }

      .core-main-menu-icon {
        color: @main-menu-default-text;
        opacity: 0.6;

        .regular-icon {
          display: block;
        }
        .solid-icon {
          display: none;
        }
      }

      &.rc-menu-item-active {
        .core-main-menu-icon {
          color: @main-menu-active;
          opacity: 1;

          .regular-icon {
            display: none;
          }

          .solid-icon {
            display: block;
          }
        }
        .core-typography-input {
          .ant-typography.default {
            color: @main-menu-active;
            font-weight: 600;
            opacity: 1;
          }
        }
      }

      &.rc-menu-item-active:not(:first-child) {
        .core-typography-input {
          .ant-typography.default {
            border-bottom: 1px @main-menu-active solid;
          }
        }
      }

      &:first-child {
        width: 100%;
        margin: 0;
        border: none;
        padding: 11px 14px 11px 0;
        border-radius: 8px 12px 8px 8px;

        .ant-typography.menu,
        .ant-typography.default {
          opacity: 1;
          white-space: nowrap;
          color: @main-menu-active;
          font-weight: 600;
        }

        &.rc-menu-item-active {
          border-radius: 8px 12px 8px 8px;
        }
      }

      &:nth-child(2) {
        margin-top: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 12px;
      }
    }
  }
}

.core-main-menu-link-block {
  display: block;
  width: 100%;
  height: 100%;
}

.core-main-menu-link-container {
  width: 100%;
  display: flex;
  > :nth-child(2) {
    flex: 1;
    margin-left: 8px;
  }
}
