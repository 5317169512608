@import "../../Theme.less";
@import "../../_variables.less";

.core-page-header {
  .title {
    display: flex;
    gap: @xs-spacing;
    align-items: baseline;
  }

  .ant-page-header {
    display: block;
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
  }

  .ant-page-header-heading {
    align-items: center;
  }

  .ant-breadcrumb {
    margin-bottom: @sm-spacing;
  }

  .ant-page-header-heading-extra > * {
    margin-left: @xs-spacing;
  }

  .ant-page-header-heading-title {
    white-space: normal;
  }

  .ant-page-header-compact .ant-page-header-heading {
    flex-wrap: nowrap;
    align-items: center;
  }

  .ant-page-header-heading-extra {
    margin: 0;
    display: flex;
  }

  .ant-page-header-heading-left {
    margin: 0;
  }

  .ant-divider-horizontal {
    border-top: 2px solid @green-100;
  }
}
