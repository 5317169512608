@import "../../Theme";

.filter-from-to-block-width {
  max-width: 568px;
}
.filter-from-to-block-width > span {
  max-width: 300px;
}

.filter-dropdown-block {
  margin-left: 18px;
  max-width: 148px;
}

.chart-builder-selector-holder {
  max-height: 76vh;
  overflow-x: auto;
}

.chart-builder-empty-chart {
  margin-top: 20px;
}

.chart-builder-chart-block {
  height: 100%;
  border: solid 1px @grey-60;
  background: @white;
  margin: 1em;
  border-radius: 4px;

  .legendtoggle {
    display: none;
  }
}

.chart-top-bar {
  background: @white;
  padding: 10px;
}
