@import "../../Theme";
@import "../../_variables";
.core-waterfall-chart {
  .legend-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: @grey-180;
    margin-right: 8px;
  }

  .chart-title {
    text-align: center;
  }
}
