@import "../../global.less";

.core-table {
  .core-table-icon-grey-120 {
    color: @grey-120;
  }

  .core-tag-container {
    font-family: "Inter", sans-serif;
  }

  .core-table-right-align {
    display: flex;
    justify-content: flex-end;
  }

  .MuiPaper-root {
    box-shadow: none;

    .MuiTablePagination-root {
      display: none;
    }

    > .MuiBox-root:first-child > .MuiBox-root {
      padding: 0;
    }

    .MuiPaper-root.MuiAlert-root {
      background-color: unset;
    }

    .MuiTableContainer-root {
      max-height: none;

      .MuiTable-root {
        .MuiTableHead-root {
          .MuiTableRow-root.MuiTableRow-head {
            .MuiTableCell-root.MuiTableCell-head {
              &:hover {
                background-color: @indigo-140;
              }
              &:active {
                background-color: @indigo-80;
              }
              &:first-child {
                border-top-left-radius: 4px;
                border-left: 1px solid @grey-80;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-right: 1px solid @grey-80;
              }
              background-color: @indigo-100;
              color: @white;
              font-weight: 600;
              font-size: 14px;
              letter-spacing: @regular;
              font-family: @body-typeface;
              border-bottom: 1px solid @grey-80;
              border-top: 1px solid @grey-80;
              padding-left: 12px;
              padding-right: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: height 150ms ease-in-out;

              .Mui-TableHeadCell-Content-Wrapper {
                overflow: visible;
                white-space: normal;
                word-break: break-word;
              }

              .MuiIconButton-root,
              .MuiButtonBase-root {
                opacity: 1;
                .core-table-column-header-icon {
                  color: @indigo-20 !important;
                  &:hover {
                    color: @white !important;
                  }
                  &:active {
                    color: @green-100 !important;
                  }
                }
              }
            }
          }
        }

        .MuiTableBody-root {
          .MuiTableRow-root {
            &:hover {
              .MuiTableCell-root {
                background-color: @grey-40;
              }
            }

            &:active {
              .MuiTableCell-root {
                background-color: @grey-80;
              }
            }

            &:last-child {
              .MuiTableCell-root {
                &:first-child {
                  border-bottom-left-radius: 4px;
                }
                &:last-child {
                  border-bottom-right-radius: 4px;
                }
              }
            }

            .MuiTableCell-root {
              &:first-child {
                border-left: 1px solid @grey-80;
                font-weight: 600;
              }
              &:last-child {
                border-right: 1px solid @grey-80;
              }
              border-bottom: 1px solid @grey-80;
              padding-left: 12px;
              padding-right: 12px;
              font-weight: 400;
              font-size: 16px;
              letter-spacing: @regular;
              color: @grey-160;
              font-family: @body-typeface;
              display: flex;
              align-items: center;
              transition: height 150ms ease-in-out;
              white-space: normal;
            }
          }
        }
      }
    }
  }
}
