@import "../../Theme";
@import "../../_variables";

@defaultBG: @grey-20;
@defaultBorder: @grey-100;
@defaultFontColor: @grey-140;

@defaultHoverBG: @grey-80;
@defaultHoverBorder: @grey-100;
@defaultHoverFontColor: @grey-140;

@defaultUnCheckBG: @grey-10;
@defaultUnCheckBorder: @grey-40;
@defaultUnCheckFontColor: @grey-40;

// indigo80
@indigo80OutlineBG: @indigo-10;
@indigo80OutlineBorder: @indigo-80;
@indigo80OutlineFontColor: @indigo-80;

@indigo80OutlineHoverBG: @indigo-20;
@indigo80OutlineHoverBorder: @indigo-120;
@indigo80OutlineHoverFontColor: @indigo-120;

@indigo80OutlineUncheckBG: @grey-10;
@indigo80OutlineUncheckBorder: @grey-40;
@indigo80OutlineUncheckFontColor: @grey-40;

@indigo80SolidBG: @indigo-80;
@indigo80SolidHoverBG: @indigo-120;
@indigo80SolidUncheckBG: @grey-60;
@indigo80SolidFontColor: @white;

// indigo100
@indigo100OutlineBG: @indigo-10;
@indigo100OutlineBorder: @indigo-100;
@indigo100OutlineFontColor: @indigo-100;

@indigo100OutlineHoverBG: @indigo-40;
@indigo100OutlineHoverBorder: @indigo-140;
@indigo100OutlineHoverFontColor: @indigo-140;

@indigo100OutlineUncheckBG: @grey-10;
@indigo100OutlineUncheckBorder: @grey-40;
@indigo100OutlineUncheckFontColor: @grey-40;

@indigo100SolidBG: @indigo-100;
@indigo100SolidHoverBG: @indigo-140;
@indigo100SolidUncheckBG: @indigo-20;
@indigo100SolidFontColor: @white;

// indigo120
@indigo120OutlineBG: @indigo-10;
@indigo120OutlineBorder: @indigo-120;
@indigo120OutlineFontColor: @indigo-120;

@indigo120OutlineHoverBG: @indigo-40;
@indigo120OutlineHoverBorder: @indigo-160;
@indigo120OutlineHoverFontColor: @indigo-160;

@indigo120OutlineUncheckBG: @grey-20;
@indigo120OutlineUncheckBorder: @grey-40;
@indigo120OutlineUncheckFontColor: @grey-40;

@indigo120SolidBG: @indigo-120;
@indigo120SolidHoverBG: @indigo-160;
@indigo120SolidUncheckBG: @grey-40;
@indigo120SolidFontColor: @white;

// indigo140
@indigo140OutlineBG: @indigo-10;
@indigo140OutlineBorder: @indigo-140;
@indigo140OutlineFontColor: @indigo-140;

@indigo140OutlineHoverBG: @indigo-40;
@indigo140OutlineHoverBorder: @black;
@indigo140OutlineHoverFontColor: @black;

@indigo140OutlineUncheckBG: @grey-20;
@indigo140OutlineUncheckBorder: @grey-40;
@indigo140OutlineUncheckFontColor: @grey-40;

@indigo140SolidBG: @indigo-140;
@indigo140SolidHoverBG: @black;
@indigo140SolidUncheckBG: @grey-40;
@indigo140SolidFontColor: @white;

// green100
@green100OutlineBG: @green-10;
@green100OutlineBorder: @green-100;
@green100OutlineFontColor: @green-100;

@green100OutlineHoverBG: @green-40;
@green100OutlineHoverBorder: @green-100;
@green100OutlineHoverFontColor: @green-100;

@green100OutlineUncheckBG: @green-10;
@green100OutlineUncheckBorder: @green-40;
@green100OutlineUncheckFontColor: @green-40;

@green100SolidBG: @green-100;
@green100SolidHoverBG: @green-140;
@green100SolidUncheckBG: @green-20;
@green100SolidFontColor: @grey-180;

// turquoise60
@turquoise60OutlineBG: @turquoise-10;
@turquoise60OutlineBorder: @turquoise-60;
@turquoise60OutlineFontColor: @turquoise-60;

@turquoise60OutlineHoverBG: @turquoise-20;
@turquoise60OutlineHoverBorder: @turquoise-60;
@turquoise60OutlineHoverFontColor: @turquoise-60;

@turquoise60OutlineUncheckBG: @turquoise-10;
@turquoise60OutlineUncheckBorder: @turquoise-20;
@turquoise60OutlineUncheckFontColor: @turquoise-20;

@turquoise60SolidBG: @turquoise-60;
@turquoise60SolidHoverBG: @turquoise-100;
@turquoise60SolidUncheckBG: @turquoise-20;
@turquoise60SolidFontColor: @white;

// turquoise100
@turquoise100OutlineBG: @turquoise-10;
@turquoise100OutlineBorder: @turquoise-100;
@turquoise100OutlineFontColor: @turquoise-100;

@turquoise100OutlineHoverBG: @turquoise-40;
@turquoise100OutlineHoverBorder: @turquoise-100;
@turquoise100OutlineHoverFontColor: @turquoise-100;

@turquoise100OutlineUncheckBG: @turquoise-10;
@turquoise100OutlineUncheckBorder: @turquoise-20;
@turquoise100OutlineUncheckFontColor: @turquoise-20;

@turquoise100SolidBG: @turquoise-100;
@turquoise100SolidHoverBG: @turquoise-140;
@turquoise100SolidUncheckBG: @turquoise-20;
@turquoise100SolidFontColor: @white;

// purple100
@purple100OutlineBG: @purple-10;
@purple100OutlineBorder: @purple-100;
@purple100OutlineFontColor: @purple-100;

@purple100OutlineHoverBG: @purple-40;
@purple100OutlineHoverBorder: @purple-100;
@purple100OutlineHoverFontColor: @purple-100;

@purple100OutlineUncheckBG: @purple-10;
@purple100OutlineUncheckBorder: @purple-20;
@purple100OutlineUncheckFontColor: @purple-20;

@purple100SolidBG: @purple-100;
@purple100SolidHoverBG: @purple-140;
@purple100SolidUncheckBG: @purple-20;
@purple100SolidFontColor: @white;

// grey80
@grey80OutlineBG: @grey-10;
@grey80OutlineBorder: @grey-80;
@grey80OutlineFontColor: @grey-100;

@grey80OutlineHoverBG: @grey-60;
@grey80OutlineHoverBorder: @grey-120;
@grey80OutlineHoverFontColor: @grey-120;

@grey80OutlineUncheckBG: @grey-10;
@grey80OutlineUncheckBorder: @grey-40;
@grey80OutlineUncheckFontColor: @grey-40;

@grey80SolidBG: @grey-80;
@grey80SolidHoverBG: @grey-120;
@grey80SolidUncheckBG: @grey-60;
@grey80SolidFontColor: @grey-140;

// grey100
@grey100OutlineBG: @grey-10;
@grey100OutlineBorder: @grey-100;
@grey100OutlineFontColor: @grey-100;

@grey100OutlineHoverBG: @grey-60;
@grey100OutlineHoverBorder: @grey-140;
@grey100OutlineHoverFontColor: @grey-140;

@grey100OutlineUncheckBG: @grey-10;
@grey100OutlineUncheckBorder: @grey-60;
@grey100OutlineUncheckFontColor: @grey-60;

@grey100SolidBG: @grey-100;
@grey100SolidHoverBG: @grey-140;
@grey100SolidUncheckBG: @grey-60;
@grey100SolidFontColor: @white;

// grey120
@grey120OutlineBG: @grey-10;
@grey120OutlineBorder: @grey-120;
@grey120OutlineFontColor: @grey-120;

@grey120OutlineHoverBG: @grey-60;
@grey120OutlineHoverBorder: @grey-160;
@grey120OutlineHoverFontColor: @grey-160;

@grey120OutlineUncheckBG: @grey-10;
@grey120OutlineUncheckBorder: @grey-60;
@grey120OutlineUncheckFontColor: @grey-60;

@grey120SolidBG: @grey-120;
@grey120SolidHoverBG: @grey-160;
@grey120SolidUncheckBG: @grey-60;
@grey120SolidFontColor: @white;

// grey140
@grey140OutlineBG: @grey-10;
@grey140OutlineBorder: @grey-140;
@grey140OutlineFontColor: @grey-140;

@grey140OutlineHoverBG: @grey-60;
@grey140OutlineHoverBorder: @grey-180;
@grey140OutlineHoverFontColor: @grey-180;

@grey140OutlineUncheckBG: @grey-10;
@grey140OutlineUncheckBorder: @grey-60;
@grey140OutlineUncheckFontColor: @grey-60;

@grey140SolidBG: @grey-140;
@grey140SolidHoverBG: @black;
@grey140SolidUncheckBG: @grey-60;
@grey140SolidFontColor: @white;

// grey180
@grey180OutlineBG: @grey-10;
@grey180OutlineBorder: @grey-180;
@grey180OutlineFontColor: @grey-180;

@grey180OutlineHoverBG: @grey-60;
@grey180OutlineHoverBorder: @black;
@grey180OutlineHoverFontColor: @black;

@grey180OutlineUncheckBG: @grey-10;
@grey180OutlineUncheckBorder: @grey-40;
@grey180OutlineUncheckFontColor: @grey-40;

@grey180SolidBG: @grey-180;
@grey180SolidHoverBG: @black;
@grey180SolidUncheckBG: @grey-60;
@grey180SolidFontColor: @white;

// black
@blackOutlineBG: @grey-60;
@blackOutlineBorder: @black;
@blackOutlineFontColor: @black;

@blackOutlineHoverBG: @grey-120;
@blackOutlineHoverBorder: @black;
@blackOutlineHoverFontColor: @black;

@blackOutlineUncheckBG: @grey-10;
@blackOutlineUncheckBorder: @grey-40;
@blackOutlineUncheckFontColor: @grey-40;

@blackSolidBG: @black;
@blackSolidHoverBG: @grey-160;
@blackSolidUncheckBG: @grey-60;
@blackSolidFontColor: @white;

// blue100
@blue100OutlineBG: @blue-10;
@blue100OutlineBorder: @blue-100;
@blue100OutlineFontColor: @blue-100;

@blue100OutlineHoverBG: @blue-40;
@blue100OutlineHoverBorder: @blue-140;
@blue100OutlineHoverFontColor: @blue-140;

@blue100OutlineUncheckBG: @blue-10;
@blue100OutlineUncheckBorder: @blue-20;
@blue100OutlineUncheckFontColor: @blue-20;

@blue100SolidBG: @blue-100;
@blue100SolidHoverBG: @blue-140;
@blue100SolidUncheckBG: @blue-20;
@blue100SolidFontColor: @white;

// lime100
@lime100OutlineBG: @lime-10;
@lime100OutlineBorder: @lime-100;
@lime100OutlineFontColor: @lime-100;

@lime100OutlineHoverBG: @lime-40;
@lime100OutlineHoverBorder: @lime-140;
@lime100OutlineHoverFontColor: @lime-140;

@lime100OutlineUncheckBG: @lime-10;
@lime100OutlineUncheckBorder: @lime-20;
@lime100OutlineUncheckFontColor: @lime-20;

@lime100SolidBG: @lime-100;
@lime100SolidHoverBG: @lime-140;
@lime100SolidUncheckBG: @lime-20;
@lime100SolidFontColor: @white;

// yellow100
@yellow100OutlineBG: @yellow-10;
@yellow100OutlineBorder: @yellow-100;
@yellow100OutlineFontColor: @yellow-100;

@yellow100OutlineHoverBG: @yellow-40;
@yellow100OutlineHoverBorder: @yellow-140;
@yellow100OutlineHoverFontColor: @yellow-140;

@yellow100OutlineUncheckBG: @yellow-10;
@yellow100OutlineUncheckBorder: @yellow-20;
@yellow100OutlineUncheckFontColor: @yellow-20;

@yellow100SolidBG: @yellow-100;
@yellow100SolidHoverBG: @yellow-140;
@yellow100SolidUncheckBG: @yellow-20;
@yellow100SolidFontColor: @white;

// orange100
@orange100OutlineBG: @orange-10;
@orange100OutlineBorder: @orange-100;
@orange100OutlineFontColor: @orange-100;

@orange100OutlineHoverBG: @orange-40;
@orange100OutlineHoverBorder: @orange-140;
@orange100OutlineHoverFontColor: @orange-140;

@orange100OutlineUncheckBG: @orange-10;
@orange100OutlineUncheckBorder: @orange-20;
@orange100OutlineUncheckFontColor: @orange-20;

@orange100SolidBG: @orange-100;
@orange100SolidHoverBG: @orange-140;
@orange100SolidUncheckBG: @orange-20;
@orange100SolidFontColor: @white;

// red100
@red100OutlineBG: @red-10;
@red100OutlineBorder: @red-100;
@red100OutlineFontColor: @red-100;

@red100OutlineHoverBG: @red-40;
@red100OutlineHoverBorder: @red-140;
@red100OutlineHoverFontColor: @red-140;

@red100OutlineUncheckBG: @red-10;
@red100OutlineUncheckBorder: @red-20;
@red100OutlineUncheckFontColor: @red-20;

@red100SolidBG: @red-100;
@red100SolidHoverBG: @red-140;
@red100SolidUncheckBG: @red-20;
@red100SolidFontColor: @white;

// pink100
@pink100OutlineBG: @pink-10;
@pink100OutlineBorder: @pink-100;
@pink100OutlineFontColor: @pink-100;

@pink100OutlineHoverBG: @pink-40;
@pink100OutlineHoverBorder: @pink-140;
@pink100OutlineHoverFontColor: @pink-140;

@pink100OutlineUncheckBG: @pink-10;
@pink100OutlineUncheckBorder: @pink-20;
@pink100OutlineUncheckFontColor: @pink-20;

@pink100SolidBG: @pink-100;
@pink100SolidHoverBG: @pink-140;
@pink100SolidUncheckBG: @pink-20;
@pink100SolidFontColor: @white;

// indigo60
@indigo60OutlineBG: @indigo-10;
@indigo60OutlineBorder: @indigo-100;
@indigo60OutlineFontColor: @indigo-100;

@indigo60OutlineHoverBG: @indigo-40;
@indigo60OutlineHoverBorder: @indigo-140;
@indigo60OutlineHoverFontColor: @indigo-140;

@indigo60OutlineUncheckBG: @indigo-10;
@indigo60OutlineUncheckBorder: @indigo-20;
@indigo60OutlineUncheckFontColor: @indigo-20;

@indigo60SolidBG: @indigo-60;
@indigo60SolidHoverBG: @indigo-100;
@indigo60SolidUncheckBG: @indigo-20;
@indigo60SolidFontColor: @white;

// green60
@green60OutlineBG: @green-10;
@green60OutlineBorder: @green-100;
@green60OutlineFontColor: @green-100;

@green60OutlineHoverBG: @green-40;
@green60OutlineHoverBorder: @green-140;
@green60OutlineHoverFontColor: @green-140;

@green60OutlineUncheckBG: @green-10;
@green60OutlineUncheckBorder: @green-20;
@green60OutlineUncheckFontColor: @green-20;

@green60SolidBG: @green-60;
@green60SolidHoverBG: @green-100;
@green60SolidUncheckBG: @green-20;
@green60SolidFontColor: @grey-180;

// purple60
@purple60OutlineBG: @purple-10;
@purple60OutlineBorder: @purple-100;
@purple60OutlineFontColor: @purple-100;

@purple60OutlineHoverBG: @purple-40;
@purple60OutlineHoverBorder: @purple-140;
@purple60OutlineHoverFontColor: @purple-140;

@purple60OutlineUncheckBG: @purple-10;
@purple60OutlineUncheckBorder: @purple-20;
@purple60OutlineUncheckFontColor: @purple-20;

@purple60SolidBG: @purple-60;
@purple60SolidHoverBG: @purple-100;
@purple60SolidUncheckBG: @purple-20;
@purple60SolidFontColor: @white;

// grey60
@grey60OutlineBG: @grey-10;
@grey60OutlineBorder: @grey-100;
@grey60OutlineFontColor: @grey-100;

@grey60OutlineHoverBG: @grey-40;
@grey60OutlineHoverBorder: @grey-140;
@grey60OutlineHoverFontColor: @grey-140;

@grey60OutlineUncheckBG: @grey-10;
@grey60OutlineUncheckBorder: @grey-20;
@grey60OutlineUncheckFontColor: @grey-20;

@grey60SolidBG: @grey-60;
@grey60SolidHoverBG: @grey-100;
@grey60SolidUncheckBG: @grey-20;
@grey60SolidFontColor: @grey-140;

// turquoise60
@turquoise60OutlineBG: @turquoise-10;
@turquoise60OutlineBorder: @turquoise-100;
@turquoise60OutlineFontColor: @turquoise-100;

@turquoise60OutlineHoverBG: @turquoise-40;
@turquoise60OutlineHoverBorder: @turquoise-140;
@turquoise60OutlineHoverFontColor: @turquoise-140;

@turquoise60OutlineUncheckBG: @turquoise-10;
@turquoise60OutlineUncheckBorder: @turquoise-20;
@turquoise60OutlineUncheckFontColor: @turquoise-20;

@turquoise60SolidBG: @turquoise-60;
@turquoise60SolidHoverBG: @turquoise-100;
@turquoise60SolidUncheckBG: @turquoise-20;
@turquoise60SolidFontColor: @white;

// blue60
@blue60OutlineBG: @blue-10;
@blue60OutlineBorder: @blue-100;
@blue60OutlineFontColor: @blue-100;

@blue60OutlineHoverBG: @blue-40;
@blue60OutlineHoverBorder: @blue-140;
@blue60OutlineHoverFontColor: @blue-140;

@blue60OutlineUncheckBG: @blue-10;
@blue60OutlineUncheckBorder: @blue-20;
@blue60OutlineUncheckFontColor: @blue-20;

@blue60SolidBG: @blue-60;
@blue60SolidHoverBG: @blue-100;
@blue60SolidUncheckBG: @blue-20;
@blue60SolidFontColor: @white;

// lime60
@lime60OutlineBG: @lime-10;
@lime60OutlineBorder: @lime-100;
@lime60OutlineFontColor: @lime-100;

@lime60OutlineHoverBG: @lime-40;
@lime60OutlineHoverBorder: @lime-140;
@lime60OutlineHoverFontColor: @lime-140;

@lime60OutlineUncheckBG: @lime-10;
@lime60OutlineUncheckBorder: @lime-20;
@lime60OutlineUncheckFontColor: @lime-20;

@lime60SolidBG: @lime-60;
@lime60SolidHoverBG: @lime-100;
@lime60SolidUncheckBG: @lime-20;
@lime60SolidFontColor: @white;

// yellow60
@yellow60OutlineBG: @yellow-10;
@yellow60OutlineBorder: @yellow-100;
@yellow60OutlineFontColor: @yellow-100;

@yellow60OutlineHoverBG: @yellow-40;
@yellow60OutlineHoverBorder: @yellow-140;
@yellow60OutlineHoverFontColor: @yellow-140;

@yellow60OutlineUncheckBG: @yellow-10;
@yellow60OutlineUncheckBorder: @yellow-20;
@yellow60OutlineUncheckFontColor: @yellow-20;

@yellow60SolidBG: @yellow-60;
@yellow60SolidHoverBG: @yellow-100;
@yellow60SolidUncheckBG: @yellow-20;
@yellow60SolidFontColor: @white;

// orange60
@orange60OutlineBG: @orange-10;
@orange60OutlineBorder: @orange-100;
@orange60OutlineFontColor: @orange-100;

@orange60OutlineHoverBG: @orange-40;
@orange60OutlineHoverBorder: @orange-140;
@orange60OutlineHoverFontColor: @orange-140;

@orange60OutlineUncheckBG: @orange-10;
@orange60OutlineUncheckBorder: @orange-20;
@orange60OutlineUncheckFontColor: @orange-20;

@orange60SolidBG: @orange-60;
@orange60SolidHoverBG: @orange-100;
@orange60SolidUncheckBG: @orange-20;
@orange60SolidFontColor: @white;

// pink60
@pink60OutlineBG: @pink-10;
@pink60OutlineBorder: @pink-100;
@pink60OutlineFontColor: @pink-100;

@pink60OutlineHoverBG: @pink-40;
@pink60OutlineHoverBorder: @pink-140;
@pink60OutlineHoverFontColor: @pink-140;

@pink60OutlineUncheckBG: @pink-10;
@pink60OutlineUncheckBorder: @pink-20;
@pink60OutlineUncheckFontColor: @pink-20;

@pink60SolidBG: @pink-60;
@pink60SolidHoverBG: @pink-100;
@pink60SolidUncheckBG: @pink-20;
@pink60SolidFontColor: @white;

.core-tag-container {
  display: inline-block;

  .unSdGoal-tag {
    :global {
      .ant-tag-close-icon {
        svg {
          color: @white;
        }
      }
    }
  }

  .ant-tag {
    border-radius: 11px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    margin-right: 0px;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 2px;

    &:hover {
      opacity: 1;
    }

    .ant-typography {
      &.p4 {
        margin-bottom: 0;
        display: inline-block;
      }
    }
  }

  .ant-tag-close-icon {
    margin-left: 0px;
  }

  &.only-icon {
    .anticon + span,
    .ant-tag > span + .anticon {
      margin-left: 0px;
    }
  }

  &.default {
    .ant-tag {
      background: @grey-20;
      border: 1px solid @grey-100;
      color: @grey-140;
      .ant-typography {
        &.p4 {
          color: @grey-140;
        }
      }
    }
    .ant-tag-close-icon {
      color: @grey-140;
    }

    &.uncheck {
      .ant-tag {
        background: @grey-80;
        border: 1px solid @grey-60;
        color: @grey-60;
        cursor: pointer;
        .ant-typography {
          &.p4 {
            color: @grey-60;
          }
        }
      }
      .ant-tag-close-icon {
        color: @grey-60;
      }
    }
    &:not(.uncheck):not(.nohover):hover {
      .ant-tag {
        background: @grey-80;
        border: 1px solid @grey-100;
        color: @grey-140;
        cursor: pointer;
        .ant-typography {
          &.p4 {
            color: @grey-140;
          }
        }
      }
      .ant-tag-close-icon {
        color: @grey-140;
        cursor: pointer;
      }
    }
  }

  @colors: indigo80, indigo100, indigo120, indigo140, green100, turquoise60,
    turquoise100, purple100, grey80, grey100, grey120, grey140, grey180, blue100,
    lime100, yellow100, orange100, red100, pink100, indigo60, green60, purple60,
    grey60, turquoise60, blue60, lime60, yellow60, orange60, pink60, black;

  .for-each-loop(@index) when (@index > 0) {
    @color: extract(@colors, @index);
    &.@{color} {
      &.outline {
        .ant-tag {
          background: ~"@{@{color}OutlineBG}";
          border: 1px solid ~"@{@{color}OutlineBorder}";
          color: ~"@{@{color}OutlineFontColor}";
          .ant-typography {
            &.p4 {
              color: ~"@{@{color}OutlineFontColor}";
            }
          }
        }
        .ant-tag-close-icon {
          color: ~"@{@{color}OutlineFontColor}";
        }

        &:not(.uncheck):not(.nohover):hover {
          .ant-tag {
            background: ~"@{@{color}OutlineHoverBG}";
            border: 1px solid ~"@{@{color}OutlineHoverBorder}";
            color: ~"@{@{color}OutlineHoverFontColor}";
            cursor: pointer;
            .ant-typography {
              &.p4 {
                color: ~"@{@{color}OutlineHoverFontColor}";
              }
            }
          }
          .ant-tag-close-icon {
            color: ~"@{@{color}OutlineHoverFontColor}";
            cursor: pointer;
          }
        }
        &.uncheck {
          .ant-tag {
            background: ~"@{@{color}OutlineUncheckBG}";
            border: 1px solid ~"@{@{color}OutlineUncheckBorder}";
            color: ~"@{@{color}OutlineUncheckFontColor}";
            cursor: pointer;
            .ant-typography {
              &.p4 {
                color: ~"@{@{color}OutlineUncheckFontColor}";
              }
            }
          }
          .ant-tag-close-icon {
            color: ~"@{@{color}OutlineUncheckFontColor}";
          }
        }
      }
      &.solid {
        @textColor: ~"@{@{color}SolidFontColor}";
        .ant-tag {
          background: ~"@{@{color}SolidBG}";
          border: 1px solid ~"@{@{color}SolidBG}";
          color: @textColor;
          .ant-typography {
            &.p4 {
              color: @textColor;
            }
          }
        }
        .ant-tag-close-icon {
          color: @textColor;
        }

        &:not(.uncheck):not(.nohover):hover {
          .ant-tag {
            background: ~"@{@{color}SolidHoverBG}";
            border: 1px solid ~"@{@{color}SolidHoverBG}";
            color: @white;
            cursor: pointer;
            .ant-typography {
              &.p4 {
                color: @white;
              }
            }
          }
          .ant-tag-close-icon {
            color: @white;
            cursor: pointer;
          }
        }
        &.uncheck {
          .ant-tag {
            background: ~"@{@{color}SolidUncheckBG}";
            border: 1px solid ~"@{@{color}SolidUncheckBG}";
            color: @white;
            cursor: pointer;
            .ant-typography {
              &.p4 {
                color: @white;
              }
            }
          }
          .ant-tag-close-icon {
            color: @white;
          }
        }
      }
      &.dashed {
        .ant-tag {
          background: ~"@{@{color}OutlineBG}";
          border: 1px dashed ~"@{@{color}OutlineBorder}";
          color: ~"@{@{color}OutlineFontColor}";
          .ant-typography {
            &.p4 {
              color: ~"@{@{color}OutlineFontColor}";
            }
          }
        }
        .ant-tag-close-icon {
          color: ~"@{@{color}OutlineFontColor}";
        }

        &:not(.uncheck):not(.nohover):hover {
          .ant-tag {
            background: ~"@{@{color}OutlineHoverBG}";
            border: 1px dashed ~"@{@{color}OutlineHoverBorder}";
            color: ~"@{@{color}OutlineHoverFontColor}";
            cursor: pointer;
            .ant-typography {
              &.p4 {
                color: ~"@{@{color}OutlineHoverFontColor}";
              }
            }
          }
          .ant-tag-close-icon {
            color: ~"@{@{color}OutlineHoverFontColor}";
            cursor: pointer;
          }
        }

        &.uncheck {
          .ant-tag {
            background: ~"@{@{color}OutlineUncheckBG}";
            border: 1px dashed ~"@{@{color}OutlineUncheckBorder}";
            color: ~"@{@{color}OutlineUncheckFontColor}";
            cursor: pointer;
            .ant-typography {
              &.p4 {
                color: ~"@{@{color}OutlineUncheckFontColor}";
              }
            }
          }
          .ant-tag-close-icon {
            color: ~"@{@{color}OutlineUncheckFontColor}";
          }
        }
      }
    }
    .for-each-loop(@index - 1);
  }
  .for-each-loop(length(@colors));

  .truncate-tag-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .truncate-display-inline {
    display: inline-block;
  }
}
