@import "../../Theme";
@import "../../_variables";

.core-loading {
  .core-loading-layout-horizontal {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 6px;
  }

  .core-loading-layout-vertical {
    display: block;
  }

  .ant-spin {
    color: @grey-120;
  }

  .ant-spin-text {
    .ant-typography {
      color: @grey-140;
    }
  }

  .ant-spin-nested-loading {
    .core-loading-layout-horizontal {
      display: flex;
      justify-content: left;
      align-items: center;
      gap: 6px;

      .ant-spin-text {
        position: initial;
        width: auto;
      }
    }

    .core-loading-layout-vertical {
      &.ant-spin {
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;

        .ant-spin-text {
          top: auto;
        }
      }
    }
  }

  .ant-spin-blur {
    opacity: 0;
  }
}
