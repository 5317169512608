// --- Primary ---
// Main Tint
@indigo-100: #242849;
@green-100: #00e9ac;
@turquoise-100: #227996;
@grey-100: #b9bbbb;

// Darker Tints
@indigo-120: #181b30;
@indigo-140: #0d101b;
@indigo-160: #05060a;
@black: #000;

@green-120: #00c290;
@green-140: #00a378;
@green-160: #008f69;

@turquoise-120: #1a5e74;
@turquoise-140: #134353;
@turquoise-160: #0b2832;

@grey-120: #979a9b;
@grey-140: #646768;
@grey-160: #3c3e3f;
@grey-180: #1e1f1f;

// Lighter Tints
@indigo-80: #363c6d;
@indigo-60: #545b96;
@indigo-40: #7d84bf;
@indigo-20: #b4b7da;
@indigo-10: #eaebf5;

@green-80: #31ffc9;
@green-60: #70ffd9;
@green-40: #adffe9;
@green-20: #d6fff4;
@green-10: #ebfffa;

@turquoise-80: #2a94b7;
@turquoise-60: #48b2d5;
@turquoise-40: #8bcee5;
@turquoise-20: #bce3f0;
@turquoise-10: #eef8fb;

@grey-80: #d8d9d9;
@grey-60: #e0e1e1;
@grey-40: #ededed;
@grey-20: #f5f5f5;
@grey-10: #fafafa;
@white: #fff;

// --- Secondary ---
// Main Tint
@blue-100: #1669b6;
@purple-100: #873ac0;
@pink-100: #e86af2;
@red-100: #d32c2c;
@orange-100: #ff9900;
@yellow-100: #ffd22e;
@lime-100: #73d13d;

// Darker Tints
@blue-120: #10497f;
@blue-140: #0b345b;
@blue-160: #082440;

@purple-120: #5d2885;
@purple-140: #35164b;
@purple-160: #220a34;

@pink-120: #d414e1;
@pink-140: #a210ad;
@pink-160: #680a6f;

@red-120: #a92323;
@red-140: #781919;
@red-160: #551111;

@orange-120: #e08700;
@orange-140: #b76e00;
@orange-160: #8f5600;

@yellow-120: #f5c000;
@yellow-140: #cca000;
@yellow-160: #a38000;

@lime-120: #59ae29;
@lime-140: #407c1d;
@lime-160: #2f5b15;

// Lighter Tints
@blue-80: #1b7dd9;
@blue-60: #499ce9;
@blue-40: #89bef0;
@blue-20: #c9e1f8;
@blue-10: #edf5fd;

@purple-80: #a062d0;
@purple-60: #bd91de;
@purple-40: #d0b0e8;
@purple-20: #e3d0f1;
@purple-10: #f6effa;

@pink-80: #ee8ef5;
@pink-60: #f3b0f8;
@pink-40: #f7c7fa;
@pink-20: #f9d9fc;
@pink-10: #fcecfd;

@red-80: #d94545;
@red-60: #de5e5e;
@red-40: #ea9a9a;
@red-20: #f5cccc;
@red-10: #fbeeee;

@orange-80: #ffad33;
@orange-60: #ffbe5c;
@orange-40: #ffd699;
@orange-20: #ffe7c2;
@orange-10: #fff7eb;

@yellow-80: #ffdc5c;
@yellow-60: #ffe485;
@yellow-40: #ffe999;
@yellow-20: #fff2c2;
@yellow-10: #fffbeb;

@lime-80: #94dc6a;
@lime-60: #ace48b;
@lime-40: #caeeb5;
@lime-20: #e1f5d6;
@lime-10: #f3fbee;

// add css native var for using at js
:root {
  --indigo-100: @indigo-100;
  --green-100: @green-100;
  --turquoise-100: @turquoise-100;
  --grey-100: @grey-100;

  // Darker Tints
  --indigo-120: @indigo-120;
  --indigo-140: @indigo-140;
  --black: @black;

  --green-120: @green-120;
  --green-140: @green-140;
  --green-160: @green-160;

  --turquoise-120: @turquoise-120;
  --turquoise-140: @turquoise-140;
  --turquoise-160: @turquoise-160;

  --grey-120: @grey-120;
  --grey-140: @grey-140;
  --grey-160: @grey-160;
  --grey-180: @grey-180;

  // Lighter Tints
  --indigo-80: @indigo-80;
  --indigo-60: @indigo-60;
  --indigo-40: @indigo-40;
  --indigo-20: @indigo-20;
  --indigo-10: @indigo-10;

  --green-80: @green-80;
  --green-60: @green-60;
  --green-40: @green-40;
  --green-20: @green-20;
  --green-10: @green-10;

  --turquoise-80: @turquoise-80;
  --turquoise-60: @turquoise-60;
  --turquoise-40: @turquoise-40;
  --turquoise-20: @turquoise-20;
  --turquoise-10: @turquoise-10;

  --grey-80: @grey-80;
  --grey-60: @grey-60;
  --grey-40: @grey-40;
  --grey-20: @grey-20;
  --grey-10: @grey-10;
  --white: @white;

  // --- Secondary ---
  // Main Tint
  --blue-100: @blue-100;
  --purple-100: @purple-100;
  --pink-100: @pink-100;
  --red-100: @red-100;
  --orange-100: @orange-100;
  --yellow-100: @yellow-100;
  --lime-100: @lime-100;

  // Darker Tints
  --blue-120: @blue-120;
  --blue-140: @blue-140;
  --blue-160: @blue-160;

  --purple-120: @purple-120;
  --purple-140: @purple-140;
  --purple-160: @purple-160;

  --pink-120: @pink-120;
  --pink-140: @pink-140;
  --pink-160: @pink-160;

  --red-120: @red-120;
  --red-140: @red-140;
  --red-160: @red-160;

  --orange-120: @orange-120;
  --orange-140: @orange-140;
  --orange-160: @orange-160;

  --yellow-120: @yellow-120;
  --yellow-140: @yellow-140;
  --yellow-160: @yellow-160;

  --lime-120: @lime-120;
  --lime-140: @lime-140;
  --lime-160: @lime-160;

  // Lighter Tints
  --blue-80: @blue-80;
  --blue-60: @blue-60;
  --blue-40: @blue-40;
  --blue-20: @blue-20;
  --blue-10: @blue-10;

  --purple-80: @purple-80;
  --purple-60: @purple-60;
  --purple-40: @purple-40;
  --purple-20: @purple-20;
  --purple-10: @purple-10;

  --pink-80: @pink-80;
  --pink-60: @pink-60;
  --pink-40: @pink-40;
  --pink-20: @pink-20;
  --pink-10: @pink-10;

  --red-80: @red-80;
  --red-60: @red-60;
  --red-40: @red-40;
  --red-20: @red-20;
  --red-10: @red-10;

  --orange-80: @orange-80;
  --orange-60: @orange-60;
  --orange-40: @orange-40;
  --orange-20: @orange-20;
  --orange-10: @orange-10;

  --yellow-80: @yellow-80;
  --yellow-60: @yellow-60;
  --yellow-40: @yellow-40;
  --yellow-20: @yellow-20;
  --yellow-10: @yellow-10;

  --lime-80: @lime-80;
  --lime-60: @lime-60;
  --lime-40: @lime-40;
  --lime-20: @lime-20;
  --lime-10: @lime-10;
}

@primary-color: @green-100;
@card-background: @white;
@layout-header-background: @indigo-100;
@font-family: "Inter", sans-serif;
@font-size-base: 14px;
@border-color-split: @grey-80;
@border-radius-base: 4px;

// Variables
@input-placeholder-color: @grey-60;
@default-font: @font-family;
@default-font-color: @grey-180;

// Button
@button-background-color-dark: @black;
@button-border: 0.1em solid @green-100;
@button-border-hover: 0.1em solid @green-120;
@button-font-color: @blue-80;
@button-font-color-hover: @white;
@button-icon-color: @green-120;

// Body
@body-background: @indigo-140;
@layout-sider-background: @indigo-140;

// Layout
@layout-body-background: @white;
@layout-footer-background: @grey-180;
@layout-footer-padding: 14px 15px;

// Menu
@menu-dark-submenu-bg: @indigo-140;
@menu-item-height: 38px;
@menu-item-font-size: 12px;
@menu-item-color: rgba(255, 255, 255, 0.65);

// Table
@table-header-bg: transparent;

// Form
@form-item-label-font-size: 14px;
@form-vertical-label-padding: 0;

// Select
@select-dropdown-bg: @white;
@select-item-selected-bg: @white;

// letter spacing
@x-relaxed: 0.02em;
@relaxed: 0.01em;
@no-spacing: 0em;
@regular: -0.01em; // body
@tight: -0.02em; // heading, stat sm, nav active
@x-tight: -0.03em; // button, table
@xx-tight: -0.04em; // input label, stat md and lg
@elevation-weak: 0 2px 30px 2px rgba(0, 0, 0, 0.05);
@elevation-intense:0 5px 16px 2px rgba(0, 0, 0, 0.22);
@header-height: 64px;

// main menu theme
@main-menu-active: @green-100;
@main-menu-default-text: @white;
@main-menu-divider: @indigo-100;
@main-menu-background: @indigo-140;
@main-menu-active-background: @indigo-160;
