@import "../../Theme";
@import "../../_variables";

.core-message {
  .message-content {
    color: @grey-160;
  }

  .ant-message-notice-content {
    color: @grey-160;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    background: @white;
    border: 1px solid @grey-100;
    box-sizing: border-box;
    box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

    .icon-info {
      color: @grey-100;
    }

    .icon-loading {
      color: @grey-80;
    }

    .icon-error {
      color: @red-100;
    }

    .icon-warning {
      color: @orange-100;
    }

    .icon-success {
      color: @lime-100;
    }
  }

  div.ant-typography {
    display: inline;
  }
}

.ant-message {
  top: 1px;
  z-index: 2001;
}
