@import "../../Theme";
@import "../../_variables";

.core-input {
  > * {
    font-family: @body-typeface;
    font-size: 14px;
  }

  .ant-input {
    &:is([disabled]) {
      opacity: 0.5;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  .ant-input-show-count-suffix {
    font-style: italic;
    font-size: 10px;
    color: @grey-100;
  }

  > .ant-input {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;
    height: 32px;

    &:not([disabled]) {
      border-color: @grey-100;

      &:hover,
      &:focus {
        border-color: @green-100;
      }

      &::placeholder {
        color: @grey-100;
      }
    }

    &:not(:placeholder-shown) {
      border-color: @grey-120;
    }

    &:is([disabled]) {
      background: @grey-40;
      border-color: @grey-100;
      opacity: 0.5;

      &::placeholder {
        color: @grey-100;
        opacity: 1;
      }
    }
  }

  > .ant-input-sm {
    height: 24px;
  }

  > .ant-input-lg {
    height: 38px;
  }

  > .ant-input-affix-wrapper {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;
    height: 32px;

    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover,
      &:focus {
        border-color: @green-100;

        .ant-input-prefix {
          color: @green-100;
        }
      }
      &.ant-input-affix-wrapper-focused {
        border-color: @green-100;
      }

      .ant-input {
        color: @grey-140;
        font-size: 14px;
        &::placeholder {
          color: @grey-100;
          opacity: 1;
        }
      }
    }
  }

  > .ant-input-affix-wrapper-sm {
    height: 24px;
  }

  > .ant-input-affix-wrapper-lg {
    height: 38px;
  }

  > .ant-input-affix-wrapper-disabled {
    background: @grey-40;
    .ant-input-prefix {
      opacity: 0.5;
    }
    .ant-input-suffix {
      opacity: 0.5;
    }
    .ant-input {
      color: @grey-140;
      font-size: 14px;
      &::placeholder {
        color: @grey-100;
      }
    }
  }

  .ant-input-group-wrapper {
    .ant-input-group {
      .ant-input {
        color: @grey-140;
      }
      .ant-input-sm {
        color: @grey-140;
        height: 24px;
      }
      .ant-input-lg {
        color: @grey-140;
        height: 38px;
      }
    }
  }

  :not(.input-disabled-addon) {
    .ant-input-wrapper {
      .ant-input {
        border-color: @grey-100;
        font-size: 14px;
        &::placeholder {
          color: @grey-100;
        }
        &:not(:placeholder-shown) {
          border-color: @grey-120;
        }
        &:focus {
          border-color: @green-100;
        }
      }
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @grey-100;
        color: @grey-140;
        text-transform: none;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }

        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        &:not(.ant-input-affix-wrapper-focused) {
          border-color: @grey-100;
        }
        &.ant-input-affix-wrapper-focused {
          border-color: @green-100;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input-prefix {
          [role="img"] {
            color: @grey-140;
          }
        }
        &.ant-input-affix-wrapper-lg {
          height: 38px;
          .ant-input {
            &.ant-input-lg {
              height: auto;
            }
          }
        }
      }
      &:hover {
        .ant-input-group-addon {
          border-color: @green-100;
        }
        .ant-input {
          border-color: @green-100;
        }
        .ant-input-prefix {
          [role="img"] {
            color: @green-100;
          }
        }
        .ant-input-affix-wrapper {
          border-color: @green-100;
        }
      }
    }
  }

  .input-disabled-addon {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        background: @grey-40;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        &:hover {
          border-color: @grey-100;
        }
        &.ant-input-affix-wrapper-lg {
          height: 38px;
          .ant-input {
            &.ant-input-lg {
              height: auto;
            }
          }
        }
      }
      .ant-input {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        font-size: 14px;
        &::placeholder {
          color: @grey-100;
          opacity: 1;
        }
      }
    }
  }

  > .ant-input-search {
    .ant-input-wrapper {
      &.ant-input-group {
        .ant-input {
          border-radius: 20px;
          border-color: @grey-100;
          color: @grey-140;

          &:not([disabled]) {
            border-color: @grey-120;

            &:hover,
            &:focus {
              border-color: @green-100;
            }

            &::placeholder {
              color: @grey-100;
            }
          }

          &:is([disabled]) {
            background: @grey-40;
            border-color: @grey-100;
            opacity: 0.5;
          }
        }

        .ant-input-group-addon {
          &:last-child {
            border-radius: 20px;
            left: 4px;
          }
        }

        .ant-input-search-button {
          border-radius: 20px;
          background: @green-100;
          border-color: @green-100;
          font-style: normal;
          font-weight: bold;
          padding: 4.8px 8px;
        }

        .ant-input-affix-wrapper {
          border-radius: 20px;
          border-color: @grey-100;
          color: @grey-140;

          &:not(.ant-input-affix-wrapper-disabled) {
            border-color: @grey-100;

            &:hover,
            &:focus {
              border-color: @green-100;
            }

            .ant-input {
              &::placeholder {
                color: @grey-100;
              }
            }
          }
        }
      }
    }

    &.ant-input-search-middle {
      .ant-input-wrapper {
        &.ant-input-group {
          .ant-input-search-button {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    &.ant-input-search-large {
      .ant-input-wrapper {
        &.ant-input-group {
          .ant-input-search-button {
            font-size: 14px;
            padding: 7.2px 11px;
          }
        }
      }
    }

    &.ant-input-search-small {
      .ant-input-wrapper {
        &.ant-input-group {
          .ant-input-search-button {
            font-size: 14px;
            padding: 2px 5px;
          }
        }
      }
    }
  }

  > .ant-input-group {
    .ant-input {
      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }
    }
  }

  > .ant-input-password {
    .ant-input {
      color: @grey-140;
      font-size: 14px;
    }
  }

  > .ant-input-sm,
  > .ant-input-lg,
  > .ant-input-group-wrapper-sm span,
  > .ant-input-group-wrapper-sm span {
    font-size: 14px;
    &.ant-input-wrapper {
      .ant-input-group-addon {
        .ant-select {
          .ant-select-arrow {
            margin-top: -8px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .set-width();

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: @grey-140;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .ant-input-group {
    .ant-input:last-child {
      border-top-right-radius: 19px;
      border-bottom-right-radius: 19px;

      .ant-select {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }
    }

    .ant-input:first-child {
      border-top-left-radius: 19px;
      border-bottom-left-radius: 19px;

      .ant-select {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }
    }

    .ant-select-open {
      border: none;
    }

    .ant-input:first-child .ant-select .ant-select-selector,
    .ant-input-group-addon:first-child .ant-select .ant-select-selector {
      border-top-left-radius: 19px;
      border-bottom-left-radius: 19px;
    }

    .ant-input:last-child .ant-select .ant-select-selector,
    .ant-input-group-addon:last-child .ant-select .ant-select-selector {
      border-top-right-radius: 19px;
      border-bottom-right-radius: 19px;
    }
  }

  .ant-select {
    &.ant-select-dropdown {
      &.ant-select-open {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }
    }
  }
}

&.ant-input-affix-wrapper-disabled {
  background: @grey-40;
  border-color: @grey-100;
  color: @grey-100;
  opacity: 0.5;

  &::placeholder {
    color: @grey-100;
    opacity: 1 !important;
  }

  .ant-input-prefix {
    opacity: 0.5;
  }

  .ant-input-suffix {
    opacity: 0.5;
  }
}

.core-input-error {
  .set-width();

  > .ant-input {
    border-radius: 20px;
    border-color: @red-80;
    color: @grey-140;

    &:not([disabled]) {
      &:hover,
      &:focus {
        border-color: @red-80;
      }
    }
  }

  > .ant-input-affix-wrapper {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;

    &.ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px @red-10;
    }

    &:not(.ant-input-affix-wrapper-disabled) {
      border-color: @red-80;

      &:hover,
      &:focus {
        border-color: @red-80;
      }
    }

    .ant-input-prefix {
      [role="img"] {
        color: @red-80;
      }
    }

    .ant-input-suffix {
      [role="img"] {
        &:not(:first-child) {
          padding-left: 5px;
        }
      }
    }
  }

  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      border-color: @red-80;
      color: @grey-140;

      &:not([disabled]) {
        &:hover,
        &:focus {
          border-color: @red-80;
        }
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px @red-10;
      }
      &:first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }
      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }

      &.ant-input-affix-wrapper-lg {
        height: 38px;

        .ant-input {
          &.ant-input-lg {
            height: auto;
          }
        }
      }
    }
    .ant-input-group-addon {
      background: @grey-40;

      span {
        color: @grey-140;
      }

      &:first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }

      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }

      .ant-select {
        min-width: 60px;

        .ant-select-selection-item {
          padding-right: 12px;
        }
        .ant-select-arrow {
          margin-top: -8px;
        }
      }
    }
    .ant-input {
      font-size: 14px;
    }
    .ant-input-prefix {
      [role="img"] {
        color: @red-80;
      }
    }

    > .ant-input-password {
      .ant-input {
        color: @grey-140;
        font-size: 14px;
      }
    }

    > .ant-input-sm,
    > .ant-input-lg,
    > .ant-input-group-wrapper-sm span,
    > .ant-input-group-wrapper-sm span {
      font-size: 14px;
    }
  }

  :not(.input-disabled-addon) {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @red-100;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        border-color: @red-100;
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input {
          border-color: @red-100;
          &::placeholder {
            color: @grey-100;
          }
          &:not(:placeholder-shown) {
            border-color: @grey-120;
          }
          &:focus {
            border-color: @red-100;
          }
        }
      }
      .ant-input {
        border-color: @red-100;
        &::placeholder {
          color: @grey-100;
        }
        &:not(:placeholder-shown) {
          border-color: @grey-120;
        }
        &:focus {
          border-color: @red-100;
        }
      }
      :focus {
        box-shadow: 0 0 0 2px @red-10;
        border-color: @red-100;
      }
    }
  }
  .input-disabled-addon {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input {
          background: @grey-40;
          border-color: @grey-100;
          opacity: 0.5;
          &::placeholder {
            color: @grey-100;
            opacity: 1;
          }
        }
        .ant-input-prefix {
          [role="img"] {
            color: @grey-120;
          }
        }
        .ant-input-suffix {
          [role="img"] {
            color: @grey-120;
          }
        }
      }
      .ant-input-affix-wrapper-disabled {
        &:hover {
          border-color: @grey-100;
        }
      }
      .ant-input {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        &::placeholder {
          color: @grey-100;
          opacity: 1;
        }
      }
    }
  }
}

.core-input-warning {
  .set-width();

  > .ant-input {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;

    &:not([disabled]) {
      border-color: @orange-80;

      &:hover,
      &:focus {
        border-color: @orange-80;
      }
    }
  }

  > .ant-input-affix-wrapper {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;

    &.ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px @orange-10;
    }

    &:not(.ant-input-affix-wrapper-disabled) {
      border-color: @orange-80;

      &:hover,
      &:focus {
        border-color: @orange-80;
      }
    }

    .ant-input-prefix {
      [role="img"] {
        color: @orange-80;
      }
    }

    .ant-input-suffix {
      [role="img"] {
        &:not(:first-child) {
          padding-left: 5px;
        }
      }
    }
  }

  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      border-color: @orange-80;
      color: @grey-140;

      &:not([disabled]) {
        &:hover,
        &:focus {
          border-color: @orange-80;
        }
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px @orange-10;
      }
      &:first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }
      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }
    }
    .ant-input-group-addon {
      background: @grey-40;

      span {
        color: @grey-140;
      }

      &:first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }

      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }

      .ant-select {
        min-width: 60px;

        .ant-select-selection-item {
          padding-right: 12px;
        }
        .ant-select-arrow {
          margin-top: -8px;
        }
      }
    }
    .ant-input {
      font-size: 14px;
    }
    .ant-input-prefix {
      [role="img"] {
        color: @orange-80;
      }
    }

    > .ant-input-password {
      .ant-input {
        color: @grey-140;
        font-size: 14px;
      }
    }

    > .ant-input-sm,
    > .ant-input-lg,
    > .ant-input-group-wrapper-sm span,
    > .ant-input-group-wrapper-sm span {
      font-size: 14px;
    }
  }
  :not(.input-disabled-addon) {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @orange-80;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        border-color: @orange-80;
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input {
          border-color: @orange-80;
          &::placeholder {
            color: @grey-100;
          }
          &:not(:placeholder-shown) {
            border-color: @grey-120;
          }
          &:focus {
            border-color: @orange-80;
          }
        }
      }
      .ant-input {
        border-color: @orange-80;
        &::placeholder {
          color: @grey-100;
        }
        &:not(:placeholder-shown) {
          border-color: @grey-120;
        }
        &:focus {
          border-color: @orange-80;
        }
      }
      :focus {
        box-shadow: 0 0 0 2px @orange-10;
        border-color: @orange-80;
      }
    }
  }
  .input-disabled-addon {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input {
          background: @grey-40;
          border-color: @grey-100;
          opacity: 0.5;
          &::placeholder {
            color: @grey-100;
            opacity: 1;
          }
        }
        .ant-input-prefix {
          [role="img"] {
            color: @grey-120;
          }
        }
        .ant-input-suffix {
          [role="img"] {
            color: @grey-120;
          }
        }
      }
      .ant-input-affix-wrapper-disabled {
        &:hover {
          border-color: @grey-100;
        }
      }
      .ant-input {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        &::placeholder {
          color: @grey-100;
          opacity: 1;
        }
      }
    }
  }
}

.core-input-success {
  .set-width();

  > .ant-input {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;

    &:not([disabled]) {
      border-color: @lime-100;

      &:hover,
      &:focus {
        border-color: @lime-100;
      }
    }
  }

  > .ant-input-affix-wrapper {
    border-radius: 20px;
    border-color: @grey-100;
    color: @grey-140;

    &.ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 2px @lime-10;
    }

    &:not(.ant-input-affix-wrapper-disabled) {
      border-color: @lime-100;

      &:hover,
      &:focus {
        border-color: @lime-100;
      }
    }

    .ant-input-prefix {
      [role="img"] {
        color: @lime-100;
      }
    }

    .ant-input-suffix {
      [role="img"] {
        &:not(:first-child) {
          padding-left: 5px;
        }
      }
    }
  }

  .ant-input-wrapper {
    .ant-input-affix-wrapper {
      border-color: @lime-100;
      color: @grey-140;

      &:not([disabled]) {
        &:hover,
        &:focus {
          border-color: @lime-100;
        }
      }
      &.ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px @lime-10;
      }
      &:first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }
      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }
    }
    .ant-input-group-addon {
      background: @grey-40;

      span {
        color: @grey-140;
      }

      &:first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
      }

      &:last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px;
      }

      .ant-select {
        min-width: 60px;

        .ant-select-selection-item {
          padding-right: 12px;
        }
        .ant-select-arrow {
          margin-top: -8px;
        }
      }
    }
    .ant-input {
      font-size: 14px;
    }
    .ant-input-prefix {
      [role="img"] {
        color: @lime-100;
      }
    }

    > .ant-input-password {
      .ant-input {
        color: @grey-140;
        font-size: 14px;
      }
    }

    > .ant-input-sm,
    > .ant-input-lg,
    > .ant-input-group-wrapper-sm span,
    > .ant-input-group-wrapper-sm span {
      font-size: 14px;
    }
  }
  :not(.input-disabled-addon) {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @lime-100;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        border-color: @lime-100;
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input {
          border-color: @lime-100;
          &::placeholder {
            color: @grey-100;
          }
          &:not(:placeholder-shown) {
            border-color: @grey-120;
          }
          &:focus {
            border-color: @lime-100;
          }
        }
      }
      .ant-input {
        border-color: @lime-100;
        &::placeholder {
          color: @grey-100;
        }
        &:not(:placeholder-shown) {
          border-color: @grey-120;
        }
        &:focus {
          border-color: @lime-100;
        }
      }
      :focus {
        box-shadow: 0 0 0 2px @lime-10;
        border-color: @lime-100;
      }
    }
  }
  .input-disabled-addon {
    .ant-input-wrapper {
      .ant-input-group-addon {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-select {
          min-width: 60px;

          .ant-select-selection-item {
            padding-right: 12px;
          }
          .ant-select-arrow {
            margin-top: -8px;
          }
        }
      }
      .ant-input-affix-wrapper {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        color: @grey-140;
        span {
          color: @grey-140;
        }
        &:first-child {
          border-top-left-radius: 19px;
          border-bottom-left-radius: 19px;
        }
        &:last-child {
          border-top-right-radius: 19px;
          border-bottom-right-radius: 19px;
        }
        .ant-input {
          background: @grey-40;
          border-color: @grey-100;
          opacity: 0.5;
          &::placeholder {
            color: @grey-100;
            opacity: 1;
          }
        }
        .ant-input-prefix {
          [role="img"] {
            color: @grey-120;
          }
        }
        .ant-input-suffix {
          [role="img"] {
            color: @grey-120;
          }
        }
      }
      .ant-input-affix-wrapper-disabled {
        &:hover {
          border-color: @grey-100;
        }
      }
      .ant-input {
        background: @grey-40;
        border-color: @grey-100;
        opacity: 0.5;
        &::placeholder {
          color: @grey-100;
          opacity: 1;
        }
      }
    }
  }
}

.core-textarea {
  .ant-input {
    height: 96px;
  }

  .textarea-hide-scroll-bar {
    scrollbar-width: none;
  }

  .set-width();

  .character-limit {
    padding-left: 14px;

    .ant-typography {
      color: @grey-120;
      font-style: italic;
    }
  }

  > * {
    font-family: @body-typeface;
    font-size: 14px;
  }

  > .ant-input {
    border-radius: 16px !important;
    border-color: @grey-80;
    color: @grey-140;

    &:not([disabled]) {
      border-color: @grey-100;

      &:hover,
      &:focus {
        border-color: @green-100;
      }

      &::placeholder {
        color: @grey-100;
        opacity: 1;
      }
    }

    &:is([disabled]) {
      background: @grey-40;
      border-color: @grey-80;
      opacity: 0.5;

      &::placeholder {
        color: @grey-100;
        opacity: 1;
      }
    }
  }

  > .core-textarea-no-resize {
    resize: none;
  }
}
